import '@progress/kendo-ui/js/kendo.multiselect.js';
import { pureComputed } from 'knockout';
import { interSubscribe } from 'Shared/Scripts/Components/Contracts/ComponentOptions';
const defaultOpts = {
    placeholder: 'Select...'
};
export class MultiSelect {
    constructor(options) {
        this.options = defaultOpts;
        this.options = { ...defaultOpts, ...options };
        if (this.options.lookupContainer) {
            this.lookupContainer = this.options.lookupContainer;
            this.postValues = pureComputed({
                read: () => this.lookupContainer.values(),
                write: (newValues) => {
                    var _a;
                    (_a = this._multiSelect) === null || _a === void 0 ? void 0 : _a.value(newValues);
                }
            });
            interSubscribe(this.lookupContainer.values, this.postValues);
        }
        else {
            throw 'A lookup container is required';
        }
    }
    koDescendantsComplete(node) {
        const $input = $(node).find('select');
        const $multiSelect = $input.kendoMultiSelect({
            dataTextField: this.lookupContainer.dataTextField,
            dataValueField: this.lookupContainer.dataValueField,
            filter: 'contains',
            minLength: 1,
            ignoreCase: true,
            highlightFirst: false,
            placeholder: this.options.placeholder,
            dataSource: {
                data: this.lookupContainer.options()
            },
            change: () => {
                var _a;
                const dataItems = (_a = this._multiSelect) === null || _a === void 0 ? void 0 : _a.dataItems();
                this.lookupContainer.values(dataItems.map(x => this.getDataValue(x)));
            }
        });
        this._multiSelect = $multiSelect === null || $multiSelect === void 0 ? void 0 : $multiSelect.data('kendoMultiSelect');
        this.lookupContainer.options.subscribe((options) => {
            var _a;
            (_a = this._multiSelect) === null || _a === void 0 ? void 0 : _a.setDataSource(new kendo.data.DataSource({
                data: options
            }));
            this.lookupContainer.values.notifySubscribers();
        });
        this.lookupContainer.values.notifySubscribers();
        $(node).find('[title]').each((_, element) => element.removeAttribute('title'));
    }
    getDataValue(dataItem) {
        return dataItem === null || dataItem === void 0 ? void 0 : dataItem[this.lookupContainer.dataValueField];
    }
}
