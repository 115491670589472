import ko, { computed, observable, pureComputed } from 'knockout';
var ToggleState;
(function (ToggleState) {
    ToggleState[ToggleState["Left"] = 0] = "Left";
    ToggleState[ToggleState["Right"] = 1] = "Right";
})(ToggleState || (ToggleState = {}));
const defaultOpts = {
    left: {
        text: 'Left',
        value: ToggleState.Left
    },
    right: {
        text: 'Right',
        value: ToggleState.Right
    },
    size: 'mini'
};
export class ToggleInput {
    constructor(options) {
        this.options = defaultOpts;
        this._rawValue = observable(ToggleState.Left);
        this.leftActive = computed(() => this._rawValue() === ToggleState.Left);
        this.rightActive = computed(() => this._rawValue() === ToggleState.Right);
        this.value = pureComputed({
            read: () => {
                return this._rawValue() === ToggleState.Left ? this.options.left.value : this.options.right.value;
            },
            write: (value) => {
                if (value === this.options.left.value) {
                    this.selectLeft();
                }
                else if (value === this.options.right.value) {
                    this.selectRight();
                } //--If no match, don't update the backing value
            }
        });
        this.options = { ...defaultOpts, ...options };
        const value = options === null || options === void 0 ? void 0 : options.value;
        if (ko.isObservable(value)) {
            this.configureBackingObservable(value);
        }
        else {
            this.value(value);
        }
        this._rawValue.valueHasMutated();
    }
    configureBackingObservable(backingObservable) {
        this.value(backingObservable());
        this.value.subscribe((newVal) => {
            backingObservable(newVal);
        });
        backingObservable.subscribe((newVal) => {
            this.value(newVal);
        });
    }
    selectLeft(data, event) {
        if (!this.options.detailsMode || !event) { //--Let internal calls get through, but don't let clicks affect the state in details mode
            this._rawValue(ToggleState.Left);
        }
    }
    selectRight(data, event) {
        if (!this.options.detailsMode || !event) { //--Let internal calls get through, but don't let clicks affect the state in details mode
            this._rawValue(ToggleState.Right);
        }
    }
}
