import { observableArray, pureComputed } from 'knockout';
import { coerceObservableOrValue } from 'Shared/Scripts/Helpers';
class NewAttachment {
    constructor(file, container) {
        this.container = container;
        this.file = file;
        this.name = this.file.name;
        this.inputName = `${this.container.options.baseInputName}.NewAttachments`;
    }
    remove() {
        this.container.newAttachments.remove(this);
    }
}
class ExistingAttachment {
    constructor(data, container) {
        this.container = container;
        this.id = data.id;
        this.name = data.name;
        this.canRemove = !coerceObservableOrValue(this.container.options.detailsMode) && this.id > 0;
        this.inputName = pureComputed(() => `${this.container.options.baseInputName}.ExistingAttachments[${this.container.existingAttachments.indexOf(this)}]`);
    }
    remove() {
        this.container.existingAttachments.remove(this);
    }
}
const defaultOpts = {
    label: 'Upload File(s)'
};
export class AttachmentContainer {
    constructor(options) {
        var _a, _b, _c, _d;
        this.options = defaultOpts;
        this.options = { ...defaultOpts, ...options };
        this.allowMultiple = this.options.maxAttachments !== 1;
        this.newAttachments = (_b = (_a = this.options.data) === null || _a === void 0 ? void 0 : _a.newAttachments) !== null && _b !== void 0 ? _b : observableArray();
        this.existingAttachments = (_d = (_c = this.options.data) === null || _c === void 0 ? void 0 : _c.existingAttachments) !== null && _d !== void 0 ? _d : observableArray();
        for (const ea of this.existingAttachments()) {
            this.existingAttachments.replace(ea, new ExistingAttachment(ea, this));
        }
        this.hasExistingAttachments = pureComputed(() => this.existingAttachments().length > 0);
        this.canAdd = pureComputed(() => !this.newAttachments().some(na => !na.file) && (this.options.maxAttachments === undefined || this.newAttachments().length < this.options.maxAttachments));
        this.newAttachmentInputId = `${this.options.baseInputName}.NewAttachments`.replace(/\./g, '_');
    }
    addNewAttachment(vm, event) {
        function isFileInput(target) {
            return (target === null || target === void 0 ? void 0 : target.files) instanceof FileList;
        }
        const target = event === null || event === void 0 ? void 0 : event.currentTarget;
        if (isFileInput(target)) {
            for (const file of target.files) {
                if (this.canAdd() && file.size) {
                    this.newAttachments.push(new NewAttachment(file, this));
                }
            }
        }
        const element = document.getElementById(this.newAttachmentInputId);
        if (element instanceof HTMLInputElement) {
            element.value = '';
        }
    }
}
