import { Guid } from 'guid-typescript';
function getOrCreateSessionId(context = window) {
    const idKey = 'amplifund-session-identifier';
    let id = context.sessionStorage.getItem(idKey);
    if (!id) {
        id = Guid.create().toString();
        context.sessionStorage.setItem(idKey, id);
    }
    return id;
}
function createSessionSymbol(symbolKey, context = window) {
    if (!symbolKey.trim()) {
        throw Error(`Invalid symbolKey: [${symbolKey}]`);
    }
    const sessionId = getOrCreateSessionId(context);
    const symbol = Symbol.for(`${symbolKey}-${sessionId}`);
    return symbol;
}
/**
 * Check if a key already has an object registered to it.
 * @param symbolKey The key of the object.
 */
export function isRegistered(symbolKey, context = window) {
    const symbol = createSessionSymbol(symbolKey, context);
    const globalSymbols = Object.getOwnPropertySymbols(context);
    const registered = globalSymbols.indexOf(symbol) > -1;
    return registered;
}
/**
 * Retrieves a registered Singleton.
 * @param symbolKey The key of the object to use for retrieval.
 */
export function getInstance(symbolKey, context = window) {
    if (isRegistered(symbolKey, context)) {
        const symbol = createSessionSymbol(symbolKey, context);
        return context[symbol];
    }
    return undefined;
}
/**
 * Registers a global instance of an object to be shared within the session.
 * @param instance The object to register.
 * @param symbolKey The key of the object to use for retrieval.
 * @param context The context to operate in.
 * Defaults to window.
 */
export function registerInstance(instance, symbolKey, context = window) {
    if (!isRegistered(symbolKey, context)) {
        const symbol = createSessionSymbol(symbolKey, context);
        context[symbol] = instance;
    }
    else {
        throw Error(`Instance already registered: SymbolKey[${symbolKey}] SessionId[${getOrCreateSessionId()}]`);
    }
}
