import 'semantic-ui-sass/js/dimmer';
import 'semantic-ui-sass/js/modal';
import 'semantic-ui-sass/js/transition';
import { ButtonGroup } from 'Shared/Scripts/DeferredButton';
import { addDimmerLoader } from 'Shared/Scripts/DimmerLoader';
import { ajaxSubmit, convertToBoolean, createElementFromTemplate, handleErrorResponse } from 'Shared/Scripts/Helpers';
import { KeyCode } from 'Shared/Scripts/Models';
import { showSuccess } from 'Shared/Scripts/Notification';
import 'Shared/Styles/modal.scss';
const defaultOpts = {
    observeChanges: true,
    autofocus: true
};
function createFooter(modalData) {
    var _a, _b;
    const footer = document.createDocumentFragment();
    if (modalData.tertiaryButton) {
        const tertiaryButton = document.createElement('a');
        tertiaryButton.id = 'tertiaryButton';
        $(tertiaryButton).addClass('ui button exclude');
        tertiaryButton.title = tertiaryButton.textContent = modalData.tertiaryButtonText || '';
        tertiaryButton.setAttribute('data-role', 'tertiary');
        footer.appendChild(tertiaryButton);
    }
    if (modalData.primaryButton) {
        let submitButton;
        switch (modalData.submitType) {
            case 'link': {
                submitButton = document.createElement('a');
                submitButton.href = (_a = modalData.submitUrl) !== null && _a !== void 0 ? _a : '#';
                break;
            }
            default:
            case 'button': {
                submitButton = document.createElement('button');
                submitButton.type = 'button';
                break;
            }
        }
        $(submitButton).addClass('ui button approve exclude');
        if (modalData.isDelete) {
            $(submitButton).addClass('btn-delete');
        }
        submitButton.title = submitButton.textContent = modalData.submitText;
        submitButton.setAttribute('data-role', 'submit');
        footer.appendChild(submitButton);
    }
    if (modalData.secondaryButton) {
        const cancelButton = document.createElement('button');
        $(cancelButton).addClass('ui button cancel exclude');
        cancelButton.title = cancelButton.textContent = (_b = modalData.cancelText) !== null && _b !== void 0 ? _b : 'Cancel';
        cancelButton.setAttribute('data-role', 'cancel');
        cancelButton.type = 'button';
        footer.appendChild(cancelButton);
    }
    return footer;
}
function getElementData(element) {
    const { afModalSize, afModalTitle, afModalContent, afModalSubmitText, afModalPrimaryButton, afModalSecondaryButton, afModalSubmitUrl, afModalSubmitType, afModalHttpMethod, afModalOverrideRedirectUrl, afModalCancelText, afModalCloseModalOnFail, afModalTertiaryButton, afModalTertiaryText } = element.data();
    return {
        content: afModalContent || '',
        httpMethod: afModalHttpMethod || 'POST',
        overrideRedirectUrl: afModalOverrideRedirectUrl || undefined,
        primaryButton: convertToBoolean(afModalPrimaryButton, true),
        secondaryButton: convertToBoolean(afModalSecondaryButton, true),
        size: afModalSize || '',
        submitText: afModalSubmitText || 'Submit',
        submitType: afModalSubmitType || 'button',
        submitUrl: afModalSubmitUrl || '#',
        title: afModalTitle || 'Submit',
        cancelText: afModalCancelText ? afModalCancelText : undefined,
        closeModalOnFail: !!afModalCloseModalOnFail,
        tertiaryButton: convertToBoolean(afModalTertiaryButton, false),
        tertiaryButtonText: afModalTertiaryText || undefined,
    };
}
async function makeRequest(modal, buttonGroup, modalData) {
    var _a;
    let result;
    try {
        result = await ajaxSubmit({
            url: modalData.submitUrl,
            type: modalData.httpMethod
        });
    }
    catch (error) {
        handleErrorResponse(error === null || error === void 0 ? void 0 : error.responseJSON, buttonGroup);
        if (modalData.closeModalOnFail) {
            modal.modal('hide');
        }
        return;
    }
    if (result.message) {
        showSuccess('Success', result.message);
    }
    const redirectUrl = (_a = modalData.overrideRedirectUrl) !== null && _a !== void 0 ? _a : result.redirectUrl;
    if (redirectUrl) {
        window.location = redirectUrl;
    }
    else {
        buttonGroup.resolve();
        modal.modal('hide');
    }
}
//--Modal 'Constructors'
export function createModal(element, opts, show = true, dimmerLoader) {
    const eventKey = 'keydown.modal-contain-focus', focusableSelector = 'a[href], input:not([type="hidden"]), button, select, textarea, [tabindex]:not([tabindex="-1"])';
    // Updated SemanticUI type definitions are not available, casting as any as a workaround.
    const modal = element.modal({
        ...defaultOpts,
        ...{
            onHidden: () => {
                modal.off(eventKey);
                element.remove();
            }
        },
        ...opts
    });
    if (dimmerLoader) {
        dimmerLoader.isActive = false;
    }
    if (show) {
        modal.modal('show');
    }
    // Extending the modal to capture tabs and escapes for accessibility
    modal.off(eventKey);
    modal.on(eventKey, focusableSelector, function (e) {
        if (e.key !== 'Tab') {
            if (e.key === 'Escape') {
                modal.modal('hide');
            }
            return;
        }
        const $currentFocusableElements = $(focusableSelector, modal), $firstFocusableElement = $currentFocusableElements[0], $lastFocusableElement = $currentFocusableElements[$currentFocusableElements.length - 1];
        let targetElement;
        if (e.currentTarget === $firstFocusableElement && e.shiftKey) {
            targetElement = $lastFocusableElement;
        }
        else if (e.currentTarget === $lastFocusableElement && !e.shiftKey) {
            targetElement = $firstFocusableElement;
        }
        else {
            return;
        }
        e.preventDefault();
        targetElement.focus();
    });
    return modal;
}
export function createModalFromModalData(data, opts, show = true) {
    var _a;
    const template = createElementFromTemplate('BasicModalTemplate');
    const $template = $(template);
    $template.addClass((_a = data.size) !== null && _a !== void 0 ? _a : '');
    $template.find('.header')[0].innerText = data.title;
    $template.find('.content')[0].innerHTML = data.content;
    $template.find('.actions').html(createFooter(data));
    const intentPatterns = [
        { pattern: /\/logout(\.\w*)*$/gmi, intent: 'logout' },
        { pattern: /\/changeorganization\/\d+$/gmi, intent: 'changeOrganization' }
    ];
    const modal = createModal($template, {
        onApprove: () => {
            var _a;
            window.navigationIntent = (_a = intentPatterns.find(ip => ip.pattern.test(data.submitUrl || ''))) === null || _a === void 0 ? void 0 : _a.intent;
            // TODO: Make Modals not closable once approved.
            const buttonGroup = new ButtonGroup(template, { submit: true, cancel: false });
            if (data.submitType === 'button' && data.submitUrl) {
                makeRequest(modal, buttonGroup, data);
            }
            return false;
        },
        ...opts,
    }, show);
    return modal;
}
export function createModalFromElementData(element, opts, show = true) {
    const data = getElementData(element);
    return createModalFromModalData(data, opts, show);
}
export async function createModalFromUrl(url, opts, show = true) {
    const loader = addDimmerLoader($('.af-wrapper'));
    loader.isActive = true;
    try {
        const response = await $.get(url);
        const $modal = $(`<div>${response}</div>`).find('.ui.modal');
        return createModal($modal, opts, show);
    }
    finally {
        loader.isActive = false;
    }
}
export async function createModalForLegacyElementAttributes(element, opts, show = true) {
    const { semanticModalUrl, semanticModalSize, semanticModalTitle } = $(element).data();
    if (semanticModalUrl && semanticModalSize && semanticModalTitle) {
        const loader = addDimmerLoader($('.af-wrapper'));
        loader.isActive = true;
        const response = await $.get(semanticModalUrl);
        const $modal = $(`<div class="ui modal ${semanticModalSize}">
			<div class="header">${semanticModalTitle}</div>
			<div class="content">${response}</div>
		</div>`);
        return createModal($modal, opts, show, loader);
    }
    throw 'Could not create modal';
}
export function listenForModalRequestClick($element, modalOptions, modalCallback) {
    $element.on('click', '[data-semantic-modal-v2-url]', async (e) => {
        if (!e.which || (e.which === 1)) {
            const { semanticModalV2Url } = $(e.currentTarget).data();
            const $modal = await createModalFromUrl(semanticModalV2Url, modalOptions);
            modalCallback === null || modalCallback === void 0 ? void 0 : modalCallback($modal);
        }
    });
}
export function legacyTrapModalFocus(modalId, focusSelector) {
    const modal = document.getElementById(modalId);
    function handleKey(e) {
        var _a, _b;
        if (e.keyCode !== KeyCode.Tab) {
            return;
        }
        const focusable = modal === null || modal === void 0 ? void 0 : modal.querySelectorAll(focusSelector);
        const tabForward = !e.shiftKey;
        if (focusable) {
            const first = focusable[0];
            const last = focusable[focusable.length - 1];
            if (tabForward) {
                if (document.activeElement === last) {
                    (_a = first) === null || _a === void 0 ? void 0 : _a.focus();
                    e.preventDefault();
                }
            }
            else {
                if (document.activeElement === first) {
                    (_b = last) === null || _b === void 0 ? void 0 : _b.focus();
                    e.preventDefault();
                }
            }
        }
    }
    return handleKey;
}
