import ko from 'knockout';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { DateTimeField } from 'Shared/Components/Fields/DateTimeField/DateTimeField';
import { DateTimeFormat, getDateTimeMoment, getDateTimeString } from 'Shared/Scripts/DateTimeLogic';
export class DateTimeFieldKnockoutOptions {
    constructor() {
        this.ariaLabel = undefined;
        this.ariaLabelledBy = undefined;
        this.detailsMode = undefined;
        this.disabled = undefined;
        this.enabled = undefined;
        this.id = undefined;
        this.label = undefined;
        this.max = undefined;
        this.min = undefined;
        this.name = undefined;
        this.showTimeZone = undefined;
        this.utcDisplay = undefined;
        this.value = undefined;
        this.detailsModeExcludeTime = undefined;
    }
}
const defaultOpts = {};
export class DateTimeFieldKnockout {
    constructor(options) {
        var _a, _b;
        this.options = defaultOpts;
        this.options = { knockout: undefined, ...defaultOpts, ...options };
        this._ko = (_a = this.options.knockout) !== null && _a !== void 0 ? _a : ko;
        const computed = (_b = this._ko.pureComputed) !== null && _b !== void 0 ? _b : this._ko.computed;
        this._rawValue = this._ko.observable();
        this._valueType = this._ko.observable('moment');
        if (this._ko.unwrap(options === null || options === void 0 ? void 0 : options.value) instanceof Date) {
            this._valueType('datetime');
        }
        else if (typeof this._ko.unwrap(options === null || options === void 0 ? void 0 : options.value) === 'string') {
            this._valueType('string');
        }
        this._momentValue = computed(() => { var _a; return (_a = getDateTimeMoment(this._rawValue())) !== null && _a !== void 0 ? _a : undefined; });
        this.value = computed({
            read: () => {
                const val = this._momentValue();
                if (this._valueType() === 'datetime') {
                    return val === null || val === void 0 ? void 0 : val.toDate();
                }
                else if (this._valueType() === 'string') {
                    return val === null || val === void 0 ? void 0 : val.toISOString();
                }
                else {
                    return val;
                }
            },
            write: (value) => {
                this._rawValue(this._ko.unwrap(value));
            }
        });
        this.postValue = computed(() => { var _a; return (_a = this._momentValue()) === null || _a === void 0 ? void 0 : _a.format(DateTimeFormat.ISO8601); });
        this.displayValue = computed(() => {
            var _a, _b;
            const detailsModeExcludeTime = this._ko.unwrap((_a = options === null || options === void 0 ? void 0 : options.detailsModeExcludeTime) !== null && _a !== void 0 ? _a : false);
            const isDetailsMode = this._ko.unwrap((_b = options === null || options === void 0 ? void 0 : options.detailsMode) !== null && _b !== void 0 ? _b : false);
            return getDateTimeString(this._momentValue(), isDetailsMode && detailsModeExcludeTime ? DateTimeFormat.ShortDate : DateTimeFormat.Standard);
        });
        const optionValue = options === null || options === void 0 ? void 0 : options.value;
        if (this._ko.isObservable(optionValue)) {
            this.configureBackingObservable(optionValue, this.value, this._rawValue);
        }
        this._rawValue(this._ko.unwrap(options === null || options === void 0 ? void 0 : options.value));
    }
    configureBackingObservable(backingObservable, localSubscribable, localObservable) {
        if (this._ko.isWriteableObservable(backingObservable) && localSubscribable) {
            localSubscribable.subscribe((newVal) => backingObservable(newVal));
        }
        backingObservable.subscribe((newVal) => localObservable(newVal));
    }
    // Render the component into the knockout template
    koDescendantsComplete(node) {
        var _a;
        const isDetailsMode = this._ko.unwrap((_a = this.options.detailsMode) !== null && _a !== void 0 ? _a : false);
        const $input = $(node).find(isDetailsMode ? 'span.af-datetime-display' : 'input.af-datetime');
        if (!this.root) {
            const $container = $input.parent();
            this.root = createRoot($container[0]);
        }
        const dateTimeComponent = React.createElement(DateTimeField, { ariaLabel: this.options.ariaLabel, ariaLabelledBy: this.options.ariaLabelledBy, detailsMode: this.options.detailsMode, detailsModeExcludeTime: this.options.detailsModeExcludeTime, disabled: this.options.disabled, enabled: this.options.enabled, id: this.options.id, knockout: this._ko, label: this.options.label, max: this.options.max, min: this.options.min, showTimeZone: this.options.showTimeZone, utcDisplay: this.options.utcDisplay, value: this.value, onChange: (newValue) => {
                this._rawValue(newValue);
            } });
        this.root.render(dateTimeComponent);
    }
}
export function getTemplate() {
    return require('Shared/Components/Fields/DateTimeField/DateTimeField.ko.html'); //eslint-disable-line
}
export function registerKnockoutDateTimeField(knockout) {
    knockout.bindingHandlers['af-datetime'] = {
        init: function (element, valueAccessor) {
            const $el = $(element);
            const bound = valueAccessor();
            const dateTimeFieldOptions = {};
            for (const [k, v] of Object.entries(bound)) {
                dateTimeFieldOptions[k] = v;
            }
            dateTimeFieldOptions.knockout = knockout;
            dateTimeFieldOptions.value = bound.value;
            const dateTimeField = new DateTimeFieldKnockout(dateTimeFieldOptions);
            const template = getTemplate();
            const $newNode = $('<span></span>');
            $newNode.html(template);
            $el.after($newNode);
            $el.remove();
            const container = $newNode[0];
            knockout.applyBindingsToDescendants(dateTimeField, container);
            dateTimeField.koDescendantsComplete(container);
        }
    };
}
window.afKnockout = window.afKnockout || {};
window.afKnockout.registerDateTimeField = registerKnockoutDateTimeField;
