export class DimmerLoader {
    constructor() {
        this.$dimmer = $('<div class="ui dimmer"><div class="ui loader"></div></div>');
    }
    set isActive(value) {
        if (value) {
            this.$dimmer.addClass('active');
        }
        else {
            this.$dimmer.removeClass('active');
        }
    }
}
export function addDimmerLoader(appendTo) {
    const dimmerLoader = new DimmerLoader();
    appendTo.append(dimmerLoader.$dimmer);
    return dimmerLoader;
}
