import { observable, pureComputed } from 'knockout';
class Tab {
    constructor(data, tabStrip) {
        this.hiddenTabClass = 'tab-content-container-hidden';
        this.tabStrip = tabStrip;
        this.title = data.title;
        this.targetElement = document.getElementById(this.targetId = data.targetId);
        this.active = pureComputed(() => this.tabStrip.activeTab() === this);
        this.active.subscribe(nowActive => {
            var _a, _b;
            if (nowActive) {
                (_a = this.targetElement) === null || _a === void 0 ? void 0 : _a.classList.remove(this.hiddenTabClass);
            }
            else {
                (_b = this.targetElement) === null || _b === void 0 ? void 0 : _b.classList.add(this.hiddenTabClass);
            }
        });
        this.active.notifySubscribers();
    }
    setAsActive() {
        this.tabStrip.activeTab(this);
        return false;
    }
}
export class TabStrip {
    constructor(options) {
        var _a;
        this.tabs = [];
        this.activeTab = observable(undefined);
        for (const tabData of (_a = options === null || options === void 0 ? void 0 : options.tabs) !== null && _a !== void 0 ? _a : []) {
            const tab = new Tab(tabData, this);
            this.tabs.push(tab);
            if (tabData.active && !this.activeTab()) {
                this.activeTab(tab);
            }
        }
        if (!this.activeTab() && this.tabs.length) {
            this.activeTab(this.tabs[0]); //--If no tab was specified to be active, use the first one
        }
        this.tabWidth = 90 / this.tabs.length;
    }
}
