import moment from 'moment';
export var DateTimeFormat;
(function (DateTimeFormat) {
    DateTimeFormat["Standard"] = "M/D/YYYY LT";
    DateTimeFormat["PaddedStandard"] = "MM/DD/YYYY hh:mm A";
    DateTimeFormat["ISO8601"] = "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]";
    DateTimeFormat["ShortDate"] = "M/D/YYYY";
})(DateTimeFormat || (DateTimeFormat = {}));
export function getDateTimeMoment(dateTime) {
    return dateTime ? moment.utc(dateTime) : null;
}
export function getDateTimeString(dateTime, format = DateTimeFormat.Standard, assumeUtc = false) {
    var _a;
    const forceUtc = [DateTimeFormat.ISO8601];
    let result = getDateTimeMoment(dateTime);
    if (assumeUtc || forceUtc.includes(format)) {
        result = result === null || result === void 0 ? void 0 : result.utc();
    }
    else {
        result = result === null || result === void 0 ? void 0 : result.local();
    }
    return (_a = result === null || result === void 0 ? void 0 : result.format(format)) !== null && _a !== void 0 ? _a : '';
}
window.afUtility = window.afUtility || {};
window.afUtility.dateTimeLogic = {
    dateTimeFormat: DateTimeFormat,
    getDateTimeMoment: getDateTimeMoment,
    getDateTimeString: getDateTimeString
};
