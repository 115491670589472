import ko, { observable, pureComputed } from 'knockout';
import numbro from 'numbro';
import { formatCurrency, formatPercentage, parseNumber, trimDecimalZeros } from 'Shared/Scripts/FormatHelper';
import { coerceObservableOrValue } from 'Shared/Scripts/Helpers';
const defaultOptions = {
    class: 'k-textbox numericTextBox',
    decimals: 0,
    negatives: false,
    output: 'number',
    requireValue: true,
    thousandsSeparated: true
};
const defaultCurrencyOptions = {
    ...defaultOptions,
    decimals: 2,
    output: 'currency',
};
const defaultPercentOptions = {
    ...defaultOptions,
    decimals: 2,
    output: 'percent',
};
export class NumericInput {
    constructor(options) {
        var _a;
        this.options = defaultOptions;
        this.isEditing = observable(false);
        this.rawValue = observable(0);
        this.displayValue = pureComputed({
            read: () => {
                const rawValue = this.rawValue();
                if (!this.options.requireValue && rawValue === undefined) {
                    return '';
                }
                else {
                    if (this.isEditing()) {
                        return (rawValue !== null && rawValue !== void 0 ? rawValue : 0).toString();
                    }
                    return this.format(rawValue);
                }
            },
            write: (value) => {
                var _a;
                if (!this.options.requireValue && (value === undefined || value === '')) {
                    this.rawValue(undefined);
                }
                else {
                    const rawValue = (_a = parseNumber(value, parsed => this.format(parsed))) !== null && _a !== void 0 ? _a : 0;
                    if (!this.options.negatives && rawValue < 0) {
                        this.rawValue(0);
                    }
                    else {
                        this.rawValue(rawValue);
                    }
                }
            }
        });
        switch (options === null || options === void 0 ? void 0 : options.output) {
            case 'currency':
                this.options = { ...defaultCurrencyOptions, ...options };
                break;
            case 'percent':
                this.options = { ...defaultPercentOptions, ...options };
                break;
            case 'number':
            default:
                this.options = { ...defaultOptions, ...options };
                break;
        }
        const value = (_a = this.options) === null || _a === void 0 ? void 0 : _a.value;
        if (ko.isObservable(value) || ko.isComputed(value)) {
            this.configureBackingObservable(value);
        }
        else if (typeof value === 'number') {
            this.rawValue(value);
        }
        this.isEditing.valueHasMutated();
    }
    get formattingOptions() {
        return {
            mantissa: this.options.decimals,
            // true/false aren't valid options for numbro, so this sidesteps that since negative values are handled in a setter above.
            negative: this.options.output === 'currency' ? 'parenthesis' : 'sign',
            output: this.options.output,
            thousandSeparated: this.options.thousandsSeparated
        };
    }
    format(value) {
        var _a;
        const concreteValue = value !== null && value !== void 0 ? value : 0;
        switch (this.options.output) {
            case 'currency':
                return formatCurrency(concreteValue);
            case 'percent':
                return formatPercentage(concreteValue, this.options.decimals);
            default: {
                const formatted = numbro(concreteValue).format(this.formattingOptions);
                if (coerceObservableOrValue(this.options.detailsMode) && this.options.trimDetailDecimals && ((_a = this.options.decimals) !== null && _a !== void 0 ? _a : 0) > 0) {
                    return trimDecimalZeros(formatted);
                }
                return formatted;
            }
        }
    }
    configureBackingObservable(backingObservable) {
        this.rawValue(backingObservable());
        if (!coerceObservableOrValue(this.options.detailsMode) && ko.isWriteableObservable(backingObservable)) {
            this.rawValue.subscribe((newVal) => {
                backingObservable(this.options.requireValue ? newVal !== null && newVal !== void 0 ? newVal : 0 : newVal);
            });
        }
        backingObservable.subscribe((newVal) => {
            this.rawValue(newVal);
        });
    }
}
