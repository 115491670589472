import { isMoment } from 'moment';
import { getDateTimeString } from './DateTimeLogic';
const ianaMap = new Map();
ianaMap.set('Afghanistan Standard Time', 'Asia/Kabul');
ianaMap.set('Alaskan Standard Time', 'America/Anchorage');
ianaMap.set('Aleutian Standard Time', 'America/Adak');
ianaMap.set('Altai Standard Time', 'Asia/Barnaul');
ianaMap.set('Arab Standard Time', 'Asia/Riyadh');
ianaMap.set('Arabian Standard Time', 'Asia/Dubai');
ianaMap.set('Arabic Standard Time', 'Asia/Baghdad');
ianaMap.set('Argentina Standard Time', 'America/Buenos_Aires');
ianaMap.set('Astrakhan Standard Time', 'Europe/Astrakhan');
ianaMap.set('Atlantic Standard Time', 'America/Halifax');
ianaMap.set('AUS Central Standard Time', 'Australia/Darwin');
ianaMap.set('Aus Central W. Standard Time', 'Australia/Eucla');
ianaMap.set('AUS Eastern Standard Time', 'Australia/Sydney');
ianaMap.set('Azerbaijan Standard Time', 'Asia/Baku');
ianaMap.set('Azores Standard Time', 'Atlantic/Azores');
ianaMap.set('Bahia Standard Time', 'America/Bahia');
ianaMap.set('Bangladesh Standard Time', 'Asia/Dhaka');
ianaMap.set('Belarus Standard Time', 'Europe/Minsk');
ianaMap.set('Bougainville Standard Time', 'Pacific/Bougainville');
ianaMap.set('Canada Central Standard Time', 'America/Regina');
ianaMap.set('Cape Verde Standard Time', 'Atlantic/Cape_Verde');
ianaMap.set('Caucasus Standard Time', 'Asia/Yerevan');
ianaMap.set('Cen. Australia Standard Time', 'Australia/Adelaide');
ianaMap.set('Central America Standard Time', 'America/Guatemala');
ianaMap.set('Central Asia Standard Time', 'Asia/Bishkek');
ianaMap.set('Central Brazilian Standard Time', 'America/Cuiaba');
ianaMap.set('Central Europe Standard Time', 'Europe/Budapest');
ianaMap.set('Central European Standard Time', 'Europe/Warsaw');
ianaMap.set('Central Pacific Standard Time', 'Pacific/Guadalcanal');
ianaMap.set('Central Standard Time', 'America/Chicago');
ianaMap.set('Central Standard Time (Mexico)', 'America/Mexico_City');
ianaMap.set('Chatham Islands Standard Time', 'Pacific/Chatham');
ianaMap.set('China Standard Time', 'Asia/Shanghai');
ianaMap.set('Cuba Standard Time', 'America/Havana');
ianaMap.set('Dateline Standard Time', 'Etc/GMT+12');
ianaMap.set('E. Africa Standard Time', 'Africa/Nairobi');
ianaMap.set('E. Australia Standard Time', 'Australia/Brisbane');
ianaMap.set('E. Europe Standard Time', 'Europe/Chisinau');
ianaMap.set('E. South America Standard Time', 'America/Sao_Paulo');
ianaMap.set('Easter Island Standard Time', 'Pacific/Easter');
ianaMap.set('Eastern Standard Time', 'America/New_York');
ianaMap.set('Eastern Standard Time (Mexico)', 'America/Cancun');
ianaMap.set('Egypt Standard Time', 'Africa/Cairo');
ianaMap.set('Ekaterinburg Standard Time', 'Asia/Yekaterinburg');
ianaMap.set('Fiji Standard Time', 'Pacific/Fiji');
ianaMap.set('FLE Standard Time', 'Europe/Kiev');
ianaMap.set('Georgian Standard Time', 'Asia/Tbilisi');
ianaMap.set('GMT Standard Time', 'Europe/London');
ianaMap.set('Greenland Standard Time', 'America/Godthab');
ianaMap.set('Greenwich Standard Time', 'Atlantic/Reykjavik');
ianaMap.set('GTB Standard Time', 'Europe/Bucharest');
ianaMap.set('Haiti Standard Time', 'America/Port-au-Prince');
ianaMap.set('Hawaiian Standard Time', 'Pacific/Honolulu');
ianaMap.set('India Standard Time', 'Asia/Calcutta');
ianaMap.set('Iran Standard Time', 'Asia/Tehran');
ianaMap.set('Israel Standard Time', 'Asia/Jerusalem');
ianaMap.set('Jordan Standard Time', 'Asia/Amman');
ianaMap.set('Kaliningrad Standard Time', 'Europe/Kaliningrad');
ianaMap.set('Korea Standard Time', 'Asia/Seoul');
ianaMap.set('Libya Standard Time', 'Africa/Tripoli');
ianaMap.set('Line Islands Standard Time', 'Pacific/Kiritimati');
ianaMap.set('Lord Howe Standard Time', 'Australia/Lord_Howe');
ianaMap.set('Magadan Standard Time', 'Asia/Magadan');
ianaMap.set('Magallanes Standard Time', 'America/Punta_Arenas');
ianaMap.set('Marquesas Standard Time', 'Pacific/Marquesas');
ianaMap.set('Mauritius Standard Time', 'Indian/Mauritius');
ianaMap.set('Middle East Standard Time', 'Asia/Beirut');
ianaMap.set('Montevideo Standard Time', 'America/Montevideo');
ianaMap.set('Morocco Standard Time', 'Africa/Casablanca');
ianaMap.set('Mountain Standard Time', 'America/Denver');
ianaMap.set('Mountain Standard Time (Mexico)', 'America/Mazatlan');
ianaMap.set('Myanmar Standard Time', 'Asia/Rangoon');
ianaMap.set('N. Central Asia Standard Time', 'Asia/Novosibirsk');
ianaMap.set('Namibia Standard Time', 'Africa/Windhoek');
ianaMap.set('Nepal Standard Time', 'Asia/Katmandu');
ianaMap.set('New Zealand Standard Time', 'Pacific/Auckland');
ianaMap.set('Newfoundland Standard Time', 'America/St_Johns');
ianaMap.set('Norfolk Standard Time', 'Pacific/Norfolk');
ianaMap.set('North Asia East Standard Time', 'Asia/Irkutsk');
ianaMap.set('North Asia Standard Time', 'Asia/Krasnoyarsk');
ianaMap.set('North Korea Standard Time', 'Asia/Pyongyang');
ianaMap.set('Omsk Standard Time', 'Asia/Omsk');
ianaMap.set('Pacific SA Standard Time', 'America/Santiago');
ianaMap.set('Pacific Standard Time', 'America/Los_Angeles');
ianaMap.set('Pacific Standard Time (Mexico)', 'America/Tijuana');
ianaMap.set('Pakistan Standard Time', 'Asia/Karachi');
ianaMap.set('Paraguay Standard Time', 'America/Asuncion');
ianaMap.set('Qyzylorda Standard Time', 'Asia/Qyzylorda');
ianaMap.set('Romance Standard Time', 'Europe/Paris');
ianaMap.set('Russia Time Zone 10', 'Asia/Srednekolymsk');
ianaMap.set('Russia Time Zone 11', 'Asia/Kamchatka');
ianaMap.set('Russia Time Zone 3', 'Europe/Samara');
ianaMap.set('Russian Standard Time', 'Europe/Moscow');
ianaMap.set('SA Eastern Standard Time', 'America/Cayenne');
ianaMap.set('SA Pacific Standard Time', 'America/Bogota');
ianaMap.set('SA Western Standard Time', 'America/La_Paz');
ianaMap.set('Saint Pierre Standard Time', 'America/Miquelon');
ianaMap.set('Sakhalin Standard Time', 'Asia/Sakhalin');
ianaMap.set('Samoa Standard Time', 'Pacific/Apia');
ianaMap.set('Sao Tome Standard Time', 'Africa/Sao_Tome');
ianaMap.set('Saratov Standard Time', 'Europe/Saratov');
ianaMap.set('SE Asia Standard Time', 'Asia/Bangkok');
ianaMap.set('Singapore Standard Time', 'Asia/Singapore');
ianaMap.set('South Africa Standard Time', 'Africa/Johannesburg');
ianaMap.set('South Sudan Standard Time', 'Africa/Juba');
ianaMap.set('Sri Lanka Standard Time', 'Asia/Colombo');
ianaMap.set('Sudan Standard Time', 'Africa/Khartoum');
ianaMap.set('Syria Standard Time', 'Asia/Damascus');
ianaMap.set('Taipei Standard Time', 'Asia/Taipei');
ianaMap.set('Tasmania Standard Time', 'Australia/Hobart');
ianaMap.set('Tocantins Standard Time', 'America/Araguaina');
ianaMap.set('Tokyo Standard Time', 'Asia/Tokyo');
ianaMap.set('Tomsk Standard Time', 'Asia/Tomsk');
ianaMap.set('Tonga Standard Time', 'Pacific/Tongatapu');
ianaMap.set('Transbaikal Standard Time', 'Asia/Chita');
ianaMap.set('Turkey Standard Time', 'Europe/Istanbul');
ianaMap.set('Turks And Caicos Standard Time', 'America/Grand_Turk');
ianaMap.set('Ulaanbaatar Standard Time', 'Asia/Ulaanbaatar');
ianaMap.set('US Eastern Standard Time', 'America/Indianapolis');
ianaMap.set('US Mountain Standard Time', 'America/Phoenix');
ianaMap.set('UTC', 'Etc/UTC');
ianaMap.set('UTC+12', 'Etc/GMT-12');
ianaMap.set('UTC+13', 'Etc/GMT-13');
ianaMap.set('UTC-02', 'Etc/GMT+2');
ianaMap.set('UTC-08', 'Etc/GMT+8');
ianaMap.set('UTC-09', 'Etc/GMT+9');
ianaMap.set('UTC-11', 'Etc/GMT+11');
ianaMap.set('Venezuela Standard Time', 'America/Caracas');
ianaMap.set('Vladivostok Standard Time', 'Asia/Vladivostok');
ianaMap.set('Volgograd Standard Time', 'Europe/Volgograd');
ianaMap.set('W. Australia Standard Time', 'Australia/Perth');
ianaMap.set('W. Central Africa Standard Time', 'Africa/Lagos');
ianaMap.set('W. Europe Standard Time', 'Europe/Berlin');
ianaMap.set('W. Mongolia Standard Time', 'Asia/Hovd');
ianaMap.set('West Asia Standard Time', 'Asia/Tashkent');
ianaMap.set('West Bank Standard Time', 'Asia/Hebron');
ianaMap.set('West Pacific Standard Time', 'Pacific/Port_Moresby');
ianaMap.set('Yakutsk Standard Time', 'Asia/Yakutsk');
ianaMap.set('Yukon Standard Time', 'America/Whitehorse');
export function getCurrentTimeZoneName(style = 'long') {
    const now = new Date();
    return now.toLocaleTimeString(undefined, { timeZoneName: style }).replace(now.toLocaleTimeString(), '').trim();
}
export function getTimeZoneAbbreviation(windowsTimeZone, dateTime = new Date()) {
    const removeTime = (timeStr, iana) => timeStr.replace(dateTime.toLocaleTimeString('default', { timeZone: iana }), '').trim();
    const getZoneStr = (iana) => removeTime(dateTime.toLocaleTimeString('default', { timeZoneName: 'short', timeZone: iana }), iana);
    const ianaTz = ianaMap.get(windowsTimeZone);
    return ianaTz ? getZoneStr(ianaTz) : windowsTimeZone;
}
export function getRealTimeZoneName(windowsTimeZone, dateTime = new Date()) {
    const removeTime = (timeStr, iana) => timeStr.replace(dateTime.toLocaleTimeString('default', { timeZone: iana }), '').trim();
    const getZoneStr = (iana) => removeTime(dateTime.toLocaleTimeString('default', { timeZoneName: 'long', timeZone: iana }), iana);
    const ianaTz = ianaMap.get(windowsTimeZone);
    return ianaTz ? getZoneStr(ianaTz) : windowsTimeZone;
}
function getDateFromMomentInput(input) {
    if (isMoment(input)) {
        return input.toDate();
    }
    else if (input instanceof Date) {
        return input;
    }
    else if (['string', 'number'].includes(typeof input)) {
        return new Date(input);
    }
    return new Date();
}
export function getShortZonedDateTimeDisplay(dateTime, windowsTimeZone, format, assumeUtc) {
    const dtString = getDateTimeString(dateTime, format, assumeUtc);
    if (!dtString) {
        return '';
    }
    const tzName = windowsTimeZone ? getRealTimeZoneName(windowsTimeZone, getDateFromMomentInput(dateTime)) : getCurrentTimeZoneName('long');
    const tzAbbr = windowsTimeZone ? getTimeZoneAbbreviation(windowsTimeZone, getDateFromMomentInput(dateTime)) : getCurrentTimeZoneName('short');
    return `<span title="${dtString} (${tzName})">${dtString} (${tzAbbr})</span>`;
}
export function getFullZonedDateTimeDisplay(dateTime, windowsTimeZone, format, assumeUtc) {
    const dtString = getDateTimeString(dateTime, format, assumeUtc);
    if (!dtString) {
        return '';
    }
    const tzName = windowsTimeZone ? getRealTimeZoneName(windowsTimeZone, getDateFromMomentInput(dateTime)) : getCurrentTimeZoneName('long');
    return `${dtString} (${tzName})`;
}
window.afUtility = window.afUtility || {};
window.afUtility.timeZoneLogic = {
    getCurrentTimeZoneName: getCurrentTimeZoneName,
    getTimeZoneAbbreviation: getTimeZoneAbbreviation,
    getRealTimeZoneName: getRealTimeZoneName,
    getShortZonedDateTimeDisplay: getShortZonedDateTimeDisplay,
    getFullZonedDateTimeDisplay: getFullZonedDateTimeDisplay
};
