import { createTheme } from '@mui/material/styles';
export const themeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#008400',
        },
        secondary: {
            main: '#840084',
        },
    },
    typography: {
        fontFamily: '"Open Sans", Sans-Serif',
        fontSize: 12,
    },
};
export const AmpliFundBaseTheme = createTheme(themeOptions);
