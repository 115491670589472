import { Guid } from 'guid-typescript';
import { observable, pureComputed } from 'knockout';
import { isEqual, toLower } from 'lodash';
import { configureCustomRule, configureRequired } from 'Shared/Scripts/CustomValidation';
export class DistinctListItem {
    constructor(allItems, data, isEditing = false) {
        this.distinctId = Guid.create().toString().replace(new RegExp('-', 'g'), '_');
        this.editName = observable('');
        this.allItems = allItems;
        this.id = data.id;
        this.name = observable(data.name);
        this.isInUse = data.isInUse;
        this.allowEdit = data.allowEdit;
        this.allowDelete = data.allowDelete;
        this.initValidation();
        this.editMode = observable(isEditing);
        this.canEdit = pureComputed(() => !this.editMode() && this.allowEdit);
        this.canSave = pureComputed(() => this.editMode() && !this.editName.error());
        this.canCancel = pureComputed(() => this.editMode() && this.name().length > 0);
        this.canRemove = pureComputed(() => this.allowDelete !== false && (!this.editMode() || !this.name().length));
    }
    initValidation() {
        configureRequired(this.name);
        configureRequired(this.editName, 'The name field is required');
        configureCustomRule(this.editName, name => {
            if (this.allItems().some(item => !isEqual(item, this) && toLower(item.editName() || item.name()) === toLower(name))) {
                return 'The Name field must be unique.';
            }
            return null;
        });
    }
    edit() {
        if (this.canEdit()) {
            this.editName(this.name());
            this.editMode(true);
        }
    }
    save() {
        if (this.canSave()) {
            this.name(this.editName());
            this.editMode(false);
        }
    }
    cancel() {
        if (this.canCancel()) {
            this.editMode(false);
        }
    }
    remove() {
        if (this.canRemove()) {
            if (typeof this.allowDelete === 'boolean') {
                this.allItems.remove(this);
            }
            else {
                this.allowDelete(this).then(() => this.allItems.remove(this));
            }
        }
    }
}
const defaultOptions = {};
export class DistinctListBuilder {
    constructor(options) {
        this.options = { ...defaultOptions, ...options };
    }
    add() {
        if (this.options.value) {
            const distinctItem = new DistinctListItem(this.options.value, {
                id: '',
                name: '',
                isInUse: false,
                allowEdit: true,
                allowDelete: true
            }, true);
            this.options.value.push(distinctItem);
            //--Push this to the end of the call stack so that knockout has a chance to render this out
            window.setTimeout(() => $(`#${distinctItem.distinctId} af-textbox input[type='text'], #${distinctItem.distinctId} af-textbox textarea`).focus());
        }
    }
}
