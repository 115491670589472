import ko from 'knockout';
export function registerIllinoisComponents() {
    ko.components.register('af-ilinvoiceaccountinglineeditcontent', {
        viewModel: {
            createViewModel: (params) => params.accountingLine
        },
        template: require('Illinois/Components/ILInvoiceAccountingLineEditContent.html'),
        synchronous: true
    });
}
