import 'jquery-ui/themes/base/base.css';
import '@progress/kendo-ui/css/web/kendo.common.min.css';
import '@progress/kendo-ui/css/web/kendo.default.min.css';
import 'Shared/Styles/semantic.scss';
import 'Shared/Styles/base.scss';
//--The above imports need to remain first and in this order!!
import '@progress/kendo-ui/js/kendo.menu';
import '@progress/kendo-ui/js/kendo.tooltip';
import { registerIllinoisComponents } from 'Illinois/Components/ComponentRegistration';
import $ from 'jquery';
import { DynamicImportLoader } from 'Shared/loader';
import { registerComponents } from 'Shared/Scripts/Components/ComponentRegistration';
import { createModalFromElementData, createModalFromUrl } from 'Shared/Scripts/ModalHelper';
import { legacyHandler, removeAllNotifications, showPersistentNotifications } from 'Shared/Scripts/Notification';
import { TimeoutNotifier } from 'Shared/Scripts/TimeoutNotifier';
import { UnobtrusiveValidation } from 'Shared/Scripts/UnobtrusiveValidation';
window.show_pnotify = legacyHandler; // eslint-disable-line @typescript-eslint/naming-convention
window.pnotify_remove_all = removeAllNotifications; // eslint-disable-line @typescript-eslint/naming-convention
export class Base {
    get hasAccountNav() {
        return $('#AccountNav').length > 0;
    }
    get hasOrganizationNav() {
        return $('#OrganizationNav').length > 0;
    }
    constructor() {
        this.init();
    }
    async init() {
        await DynamicImportLoader.loadPageSpecific();
        showPersistentNotifications();
        this.formsInit();
        this.addAntiForgeryTokenCloner();
        this.addOrganizationNav();
        this.addAccountNav();
        this.addToolTips();
        this.listenForConfirmationModalCreation();
        this.listenForOptionsModalClick();
        registerComponents();
        registerIllinoisComponents();
        $('#PageLoadingMask').hide();
    }
    formsInit() {
        UnobtrusiveValidation.addToAllForms();
        // handle the `form` attribute on submit buttons since IE11 can't handle it
        if ('ActiveXObject' in window) { //--If Internet Explorer
            //--Would be better if we could detect whether or not ANY browser lacks support for this feature
            $('body').on('click', 'button[type=submit][form]', function (e) {
                if (!e.which || e.which === 1) {
                    //--Submit the specified form this way, as IE can't handle this attribute without help
                    $(`form#${$(this).attr('form')}`).submit();
                }
            });
        }
    }
    addAntiForgeryTokenCloner() {
        $(document).on('submit', 'form', e => {
            const antiForgeryToken = $('#AntiForgeryToken > input').clone();
            const form = $(e.currentTarget);
            form.append(antiForgeryToken);
        });
    }
    addOrganizationNav() {
        if (this.hasOrganizationNav) {
            new kendo.ui.Menu($('#OrganizationNav > ul')[0], {
                animation: false,
                openOnClick: {
                    rootMenuItems: true,
                    subMenuItems: true
                },
                closeOnClick: true
            });
        }
    }
    addAccountNav() {
        if (this.hasAccountNav) {
            new kendo.ui.Menu($('#AccountNav > ul')[0], {
                animation: false,
                openOnClick: {
                    rootMenuItems: true,
                    subMenuItems: true
                },
                closeOnClick: true
            });
        }
    }
    addToolTips() {
        if (document.getElementById('afWebpackFullLayout')) {
            $(document).kendoTooltip({
                filter: 'a[title]:not(.noAutoTooltip)',
                width: 120,
                position: 'top',
                showAfter: 600
            });
            $(document).kendoTooltip({
                filter: 'input[title]:not(.noAutoTooltip)',
                width: 120,
                position: 'top',
                showAfter: 600
            });
            $(document).kendoTooltip({
                filter: 'div[title]:not(.noAutoTooltip)',
                width: 120,
                position: 'top',
                showAfter: 600
            });
            $(document).kendoTooltip({
                filter: 'button[title]:not(.noAutoTooltip)',
                width: 120,
                position: 'top',
                showAfter: 600
            });
            $(document).kendoTooltip({
                filter: 'i[title]:not(.noAutoTooltip)',
                width: 120,
                position: 'top',
                showAfter: 600
            });
            $(document).kendoTooltip({
                filter: 'span[title]:not([data-widetooltip])',
                width: 120,
                position: 'top',
                showAfter: 600
            });
            $(document).kendoTooltip({
                filter: 'span[data-widetooltip]:not(.noAutoTooltip)',
                width: 500,
                position: 'top',
                showAfter: 600,
                show: (e) => {
                    $(e.sender.popup.element).addClass('wideToolTip');
                }
            });
        }
    }
    listenForConfirmationModalCreation() {
        $('body').on('mousedown', 'a[data-af-modal-submit-url]', e => {
            if (!e.which || (e.which === 1)) {
                createModalFromElementData($(e.currentTarget));
            }
        });
    }
    listenForOptionsModalClick() {
        $('#AfOptionsBar a[data-semantic-modal-v2-url]').on('click', function (e) {
            var _a;
            if (!e.which || e.which === 1) {
                if (e.target.id === 'systemViewSaveModalButton' && e.target.getAttribute('data-url')) {
                    import('Shared/Scripts/SystemViews').then(sv => sv.initialize(e.target.getAttribute('data-url') || ''));
                }
                else {
                    createModalFromUrl(((_a = e.target.parentElement) === null || _a === void 0 ? void 0 : _a.getAttribute('data-semantic-modal-v2-url')) || '');
                }
            }
        });
    }
}
$(() => {
    new Base();
    TimeoutNotifier.createNotifier();
});
