import ko, { observable, pureComputed } from 'knockout';
import { createKendoEditorOptions } from 'Shared/Scripts/KendoInputHelpers';
const defaultOptions = {
    size: 40,
    class: 'k-textbox',
    type: 'text'
};
const textAreaOptions = {
    ...defaultOptions,
    multiline: true,
    rows: 7,
    size: 80
};
const countOptions = {
    ...defaultOptions,
    valueUpdate: 'input',
    autocomplete: 'off'
};
const textAreaCountOptions = {
    ...textAreaOptions,
    valueUpdate: 'input',
    autocomplete: 'off'
};
const passwordOptions = {
    ...defaultOptions,
    password: true,
    type: 'password'
};
export class TextBox {
    constructor(options) {
        var _a;
        this.options = defaultOptions;
        this.isEditing = observable(false);
        this._rawValue = observable();
        this.value = pureComputed({
            read: () => {
                return this._rawValue();
            },
            write: (value) => {
                this._rawValue(value);
            }
        });
        this.valueWithBreaks = pureComputed({
            read: () => {
                var _a;
                return (_a = this._rawValue()) === null || _a === void 0 ? void 0 : _a.replace(/(\r\n)|(\r)|(\n)/g, '<br>');
            }
        });
        this.options = (options === null || options === void 0 ? void 0 : options.password) ? { ...passwordOptions, ...options } : ((options === null || options === void 0 ? void 0 : options.multiline) ? ((options === null || options === void 0 ? void 0 : options.maxLength) ? { ...textAreaCountOptions, ...options } : { ...textAreaOptions, ...options }) : ((options === null || options === void 0 ? void 0 : options.maxLength) ? { ...countOptions, ...options } : { ...defaultOptions, ...options }));
        const value = (_a = this.options) === null || _a === void 0 ? void 0 : _a.value;
        if (ko.isObservable(value)) {
            this.configureBackingObservable(value);
        }
        else if (typeof value === 'string') {
            this.value(value);
        }
        this.length = pureComputed(() => { var _a, _b; return (_b = (_a = this._rawValue()) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0; });
        this.width = pureComputed(() => { var _a, _b, _c; return (_c = (_b = $(`#${(_a = this.options) === null || _a === void 0 ? void 0 : _a.id}`)) === null || _b === void 0 ? void 0 : _b.width()) !== null && _c !== void 0 ? _c : 0; });
        this.isEditing.valueHasMutated();
    }
    configureBackingObservable(backingObservable) {
        this.value(backingObservable());
        this.value.subscribe((newVal) => backingObservable(newVal));
        backingObservable.subscribe((newVal) => this.value(newVal));
    }
    koDescendantsComplete(node) {
        if (this.options.rich) {
            const disabled = this.options.disable === true || this.options.enable === false;
            const options = createKendoEditorOptions({
                tools: disabled ? [] : undefined
            });
            $(node).find('textarea').kendoEditor(options);
            if (disabled) {
                $($(node).find('textarea').data().kendoEditor.body).attr('contenteditable', 'false');
            }
        }
    }
}
