import '@progress/kendo-ui/js/kendo.editor';
export function createKendoUploadOptions(options) {
    const baseOptions = {
        validation: {
            allowedExtensions: ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.txt', '.dot', '.dotx', '.xlt', '.xltx', '.jpg', '.jpeg', '.png', '.tif', '.tiff', '.bmp', '.csv', '.zip']
        }
    };
    return { ...baseOptions, ...options };
}
//--Taken from editor.js & amplifund-base.js
function cleanHTML(e) {
    var _a;
    //--Remove line breaks / Mso classes
    let output = e.html.replace(/(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g, ' ');
    //--Strip Word generated HTML comments
    output = output.replace(new RegExp('<!--(.*?)-->', 'g'), '');
    //--Remove tags leave content if any
    //	Applying the RegEx strips off content between <li> and </li> tags.
    output = output.replace(new RegExp('<(/)*(meta|link|\\?xml:|st1:|o:|font)(.*?)>', 'gi'), '');
    //--Remove everything in between and including tags '<style(.)style(.)>'
    for (const badTag of ['style', 'script', 'applet', 'embed', 'noframes', 'noscript']) {
        output = output.replace(new RegExp(`<${badTag}.*?${badTag}(.*?)>`, 'gi'), '');
    }
    //--Remove attributes ' style="..."'
    for (const badAttribute of ['start']) {
        output = output.replace(new RegExp(` ${badAttribute}=\\"(.*?)\\"`, 'gi'), '');
    }
    //--Check any bookmark links and if exists update those
    for (const link of (_a = output.match(new RegExp(' href=\\"file(.*?)\\"', 'gi'))) !== null && _a !== void 0 ? _a : []) {
        const index = link.indexOf('#');
        if (index > -1) {
            output = output.replace(link, ` href=\\"${link.substring(index, link.lastIndexOf('"'))}\\"`);
        }
    }
    e.html = '';
    e.sender.paste(output, undefined);
}
export function createKendoEditorOptions(options) {
    const baseOptions = {
        encoded: false,
        paste: cleanHTML,
        tools: [
            {
                name: 'bold'
            },
            {
                name: 'italic'
            },
            {
                name: 'underline'
            },
            {
                name: 'justifyLeft'
            },
            {
                name: 'justifyCenter'
            },
            {
                name: 'justifyRight'
            },
            {
                name: 'justifyFull'
            },
            {
                name: 'insertUnorderedList'
            },
            {
                name: 'insertOrderedList'
            },
            {
                name: 'formatting'
            },
            {
                name: 'createLink'
            },
            {
                name: 'unlink'
            }
        ]
    };
    return { ...baseOptions, ...options };
}
