import { getInstance, registerInstance } from 'Shared/Scripts/SingletonHelper';
export var SessionEvent;
(function (SessionEvent) {
    SessionEvent["TimedOut"] = "sessionTimedOut";
    SessionEvent["LoggedOut"] = "sessionLoggedOut";
    SessionEvent["ExpirationInvalidated"] = "sessionExpirationInvalidated";
    SessionEvent["ExpirationUpdated"] = "sessionExpirationUpdated";
    SessionEvent["Renewed"] = "sessionRenewed";
    SessionEvent["Reauthenticated"] = "sessionReauthenticated";
    SessionEvent["ReauthenticationRequested"] = "sessionReauthenticationRequested";
})(SessionEvent || (SessionEvent = {}));
/** Used to send and receive events between browser windows and tabs. */
export class Intercom {
    constructor(context = window, storageArea = context.localStorage) {
        this.context = context;
        this.storageArea = storageArea;
        this.eventKey = 'amplifund-intercom-event';
        this.listeners = {};
        this.attachListener();
    }
    attachListener() {
        this.context.addEventListener('storage', (event) => {
            // hasFocus() check prevents IE's incorrect implementation (shocking) from infinite-looping.
            if (!this.context.document.hasFocus()
                && event && event.storageArea === this.storageArea
                && event.key === this.eventKey && event.newValue) {
                const deserialized = JSON.parse(event.newValue);
                this.receiveEvent(deserialized);
            }
        });
    }
    receiveEvent(event) {
        for (const callback of this.listeners[event.category] || []) {
            callback(event);
        }
    }
    /**
     * Propagates event information to all subscribers.
     * @param event The event (and optional data) to be propagated to listeners.
     * @param receiveEventOnCurrentTab Whether or not to notify listeners on the current tab about the event.
     * Defaults to false.
     */
    send(event, receiveEventOnCurrentTab = false) {
        if (event && event.category) {
            const serialized = JSON.stringify(event);
            this.storageArea.setItem(this.eventKey, serialized);
            if (receiveEventOnCurrentTab) {
                this.receiveEvent(event);
            }
        }
    }
    /**
     * Subscribe to an event.
     * @param request The category or categories to subscribe to, with the handler to be invoked when the event(s) occur.
     */
    on(request) {
        const flattened = Array(request.category).flat();
        for (const category of flattened) {
            this.listeners[category] = this.listeners[category] || [];
            this.listeners[category].push(request.handler);
        }
    }
    /**
     * Removes an event listener.
     * @param request The category or categories subscribed to, with the handler that is to be removed.
     */
    off(request) {
        const flattened = Array(request.category).flat();
        for (const category of flattened) {
            const handlers = this.listeners[category] || [];
            this.listeners[category] = handlers.filter((x) => x !== request.handler);
        }
    }
    /**
     * Retrieve or create an Intercom instance.
     * @param context Context to operate in.
     * Defaults to window.
     * @param storageArea StorageArea to use for events.
     * Defaults to localStorage.
     */
    static create(context = window, storageArea = context.localStorage) {
        const singletonKey = 'amplifund-intercom';
        let instance = getInstance(singletonKey, context);
        if (!instance) {
            instance = new Intercom(context, storageArea);
            registerInstance(instance, singletonKey, context);
        }
        return instance;
    }
}
