import '@progress/kendo-ui/js/kendo.datepicker.js';
import '@progress/kendo-ui/js/kendo.datetimepicker.js';
import ko, { observable, pureComputed } from 'knockout';
import moment from 'moment';
export function convertLocalDateToUtcMoment(local) {
    return moment.utc([local.getFullYear(), local.getMonth(), local.getDate(), local.getHours(), local.getMinutes()]);
}
export function formatDate(utc, includeTime) {
    return utc.format(includeTime ? 'l LT' : 'l'); //--Local short date (and time) formats
}
const defaultOpts = {};
// https://www.npmjs.com/package/semantic-ui-calendar
export class DeprecatedDateInput {
    constructor(options) {
        var _a;
        this.options = defaultOpts;
        this._rawUtc = observable();
        this.options = { ...defaultOpts, ...options };
        this.utcValue = pureComputed({
            read: () => {
                const utc = this._rawUtc();
                return this.options.includeTime ? utc : utc === null || utc === void 0 ? void 0 : utc.startOf('day');
            },
            write: (value) => {
                if (value) {
                    const utc = moment.utc(value);
                    this._rawUtc(this.options.includeTime ? utc : utc.startOf('day'));
                }
                else {
                    this._rawUtc(undefined);
                }
            }
        });
        this.postValue = pureComputed(() => { var _a; return (_a = this.utcValue()) === null || _a === void 0 ? void 0 : _a.toISOString(); });
        this.displayValue = pureComputed(() => {
            var _a;
            const utc = this.utcValue();
            return utc ? formatDate(utc, (_a = this.options.includeTime) !== null && _a !== void 0 ? _a : false) : undefined;
        });
        const value = options === null || options === void 0 ? void 0 : options.value;
        if (ko.isObservable(value)) {
            this.configureBackingObservable(value);
        }
        else {
            this.utcValue((_a = value) !== null && _a !== void 0 ? _a : moment.utc());
        }
    }
    configureBackingObservable(backingObservable) {
        this.utcValue(backingObservable());
        this.utcValue.subscribe((newVal) => backingObservable(newVal));
        backingObservable.subscribe((newVal) => this.utcValue(newVal));
    }
    koDescendantsComplete(node) {
        var _a;
        const $input = $(node).find('input:first-child');
        if (this.options.includeTime) {
            $input.kendoDateTimePicker({
                change: (e) => this.pickerChange(e)
            });
        }
        else {
            $input.kendoDatePicker({
                change: (e) => this.pickerChange(e)
            });
        }
        const value = (_a = this.options) === null || _a === void 0 ? void 0 : _a.value;
        if (ko.isObservable(value)) {
            this.configureBackingObservable(value);
        }
    }
    pickerChange(event) {
        const value = event.sender.value();
        if (value instanceof Date) {
            this._rawUtc(convertLocalDateToUtcMoment(value));
        }
        else {
            this._rawUtc(undefined);
        }
    }
}
