import { createTheme, ThemeProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ko from 'knockout';
import React, { useEffect, useState } from 'react';
import { AmpliFundBaseTheme } from 'Shared/Components/Fields/AmpliFundBaseTheme';
import { DateFormat, getDateMoment, getDateString } from 'Shared/Scripts/DateLogic';
export var PostTimeOption;
(function (PostTimeOption) {
    PostTimeOption[PostTimeOption["Standard"] = 0] = "Standard";
    PostTimeOption[PostTimeOption["Zero"] = 1] = "Zero";
    PostTimeOption[PostTimeOption["Noon"] = 2] = "Noon";
})(PostTimeOption || (PostTimeOption = {}));
const calendarPickerFontSize = 12;
const dateTheme = createTheme(AmpliFundBaseTheme, {
    components: {
        MuiDayCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    fontSize: calendarPickerFontSize
                }
            }
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    fontSize: calendarPickerFontSize
                }
            }
        }
    }
});
const buildMoment = (value, knockout) => {
    const unwrapped = knockout.unwrap(value);
    if (!unwrapped) {
        return null;
    }
    return getDateMoment(unwrapped);
};
export const DateField = ({ ariaLabel, ariaLabelledBy, detailsMode, detailsModeCanFocusOnTabbing, disabled, enabled, id, knockout, label, maxDate, minDate, name, onChange, postTime, nestedLayout, value }) => {
    var _a;
    const _ko = knockout !== null && knockout !== void 0 ? knockout : ko;
    const [momentValue, setMomentValue] = useState(buildMoment(value, _ko));
    const [workingValue, setWorkingValue] = useState(momentValue);
    const [maxDateValue, setMaxDateValue] = useState(buildMoment(maxDate, _ko));
    const [minDateValue, setMinDateValue] = useState(buildMoment(minDate, _ko));
    const componentProperties = {
        format: DateFormat.Standard,
        disabled: disabled || enabled === false,
        label: label,
        maxDate: maxDateValue || undefined,
        minDate: minDateValue || undefined,
        name: name,
        onAccept: (val) => {
            setWorkingValue(val);
            onChange === null || onChange === void 0 ? void 0 : onChange(val);
        },
        onChange: (val) => {
            setWorkingValue(val);
        },
        slotProps: {
            field: {
                inputProps: {
                    'data-testid': 'date-component',
                    onBlur: () => {
                        onChange === null || onChange === void 0 ? void 0 : onChange(workingValue);
                    }
                },
                shouldRespectLeadingZeros: true
            }
        },
        timezone: 'system'
    };
    useEffect(() => {
        if (value) {
            setMomentValue(buildMoment(value, _ko));
        }
        if (_ko.isSubscribable(value)) {
            value.subscribe((val) => {
                setMomentValue(buildMoment(val, _ko));
            });
        }
    }, [_ko.unwrap(value)]);
    useEffect(() => {
        setMaxDateValue(buildMoment(maxDate, _ko));
    }, [_ko.unwrap(maxDate)]);
    useEffect(() => {
        setMinDateValue(buildMoment(minDate, _ko));
    }, [_ko.unwrap(minDate)]);
    if (onChange !== undefined) {
        componentProperties.value = momentValue;
    }
    else {
        componentProperties.defaultValue = momentValue;
    }
    if (nestedLayout) {
        componentProperties.slotProps.popper = {
            disablePortal: true
        };
    }
    const fieldSection = (_a = componentProperties.slotProps) === null || _a === void 0 ? void 0 : _a.field;
    const inputSection = fieldSection.inputProps;
    inputSection.onFocus = function (event) {
        event.stopPropagation();
    };
    if (id) {
        fieldSection.id = id;
    }
    if (ariaLabel) {
        inputSection['aria-label'] = ariaLabel;
    }
    if (ariaLabelledBy) {
        inputSection['aria-labelledby'] = ariaLabelledBy;
    }
    let postTimeFormat;
    switch (postTime) {
        case PostTimeOption.Noon:
            postTimeFormat = DateFormat.PostNoon;
            break;
        case PostTimeOption.Standard:
            postTimeFormat = DateFormat.Post;
            break;
        default:
            postTimeFormat = DateFormat.PostZero;
            break;
    }
    const postingInput = name && React.createElement("input", { type: 'hidden', "data-testid": 'posting-input', name: name, value: getDateString(momentValue, postTimeFormat), "aria-label": ariaLabel });
    return detailsMode ?
        React.createElement(React.Fragment, null,
            postingInput,
            React.createElement("span", { id: id, tabIndex: detailsModeCanFocusOnTabbing ? 0 : undefined, "data-testid": 'details-mode' }, getDateString(momentValue))) :
        React.createElement("div", { className: 'af-input' },
            postingInput,
            React.createElement(ThemeProvider, { theme: dateTheme },
                React.createElement(LocalizationProvider, { dateAdapter: AdapterMoment },
                    React.createElement(DatePicker, { ...componentProperties }))));
};
