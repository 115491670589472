import ko from 'knockout';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { DateField, PostTimeOption } from 'Shared/Components/Fields/DateField/DateField';
import { DateFormat, getDateMoment, getDateString } from 'Shared/Scripts/DateLogic';
const defaultOpts = {};
export class DateFieldKnockout {
    constructor(options) {
        var _a, _b;
        this.options = defaultOpts;
        this.options = { knockout: undefined, ...defaultOpts, ...options };
        this._ko = (_a = this.options.knockout) !== null && _a !== void 0 ? _a : ko;
        const computed = (_b = this._ko.pureComputed) !== null && _b !== void 0 ? _b : this._ko.computed;
        this._rawValue = this._ko.observable();
        this._valueType = this._ko.observable('moment');
        if (this._ko.unwrap(options === null || options === void 0 ? void 0 : options.value) instanceof Date) {
            this._valueType('date');
        }
        else if (typeof this._ko.unwrap(options === null || options === void 0 ? void 0 : options.value) === 'string') {
            this._valueType('string');
        }
        this._momentValue = computed(() => { var _a; return (_a = getDateMoment(this._rawValue())) !== null && _a !== void 0 ? _a : undefined; });
        let postTimeFormat;
        switch (this.options.postTime) {
            case PostTimeOption.Noon:
                postTimeFormat = DateFormat.PostNoon;
                break;
            case PostTimeOption.Standard:
                postTimeFormat = DateFormat.Post;
                break;
            default:
                postTimeFormat = DateFormat.PostZero;
                break;
        }
        this.value = computed({
            read: () => {
                const val = this._momentValue();
                if (this._valueType() === 'date') {
                    return val === null || val === void 0 ? void 0 : val.toDate();
                }
                else if (this._valueType() === 'string') {
                    return getDateString(val, postTimeFormat);
                }
                else {
                    return val;
                }
            },
            write: (value) => {
                this._rawValue(this._ko.unwrap(value));
            }
        });
        this.postValue = computed(() => getDateString(this._momentValue(), postTimeFormat));
        this.displayValue = computed(() => getDateString(this._momentValue()));
        const optionValue = options === null || options === void 0 ? void 0 : options.value;
        if (this._ko.isObservable(optionValue)) {
            this.configureBackingObservable(optionValue, this.value, this._rawValue);
        }
        this._rawValue(this._ko.unwrap(options === null || options === void 0 ? void 0 : options.value));
        this._maxDateInput = this._ko.observable(this._ko.unwrap(this.options.max));
        this._maxDate = computed(() => { var _a; return (_a = getDateMoment(this._maxDateInput())) !== null && _a !== void 0 ? _a : undefined; });
        const maxDateValue = options === null || options === void 0 ? void 0 : options.max;
        if (this._ko.isObservable(maxDateValue)) {
            this.configureBackingObservable(maxDateValue, this._maxDate, this._maxDateInput);
        }
        this._minDateInput = this._ko.observable(this._ko.unwrap(this.options.min));
        this._minDate = computed(() => { var _a; return (_a = getDateMoment(this._minDateInput())) !== null && _a !== void 0 ? _a : undefined; });
        const minDateValue = options === null || options === void 0 ? void 0 : options.min;
        if (this._ko.isObservable(minDateValue)) {
            this.configureBackingObservable(minDateValue, this._minDate, this._minDateInput);
        }
    }
    configureBackingObservable(backingObservable, localSubscribable, localObservable) {
        if (this._ko.isWriteableObservable(backingObservable)) {
            localSubscribable.subscribe((newVal) => backingObservable(newVal));
        }
        backingObservable.subscribe((newVal) => localObservable(newVal));
    }
    // Render the component into the knockout template
    koDescendantsComplete(node) {
        var _a, _b, _c;
        const isDetailsMode = this._ko.unwrap((_a = this.options.detailsMode) !== null && _a !== void 0 ? _a : false);
        const $input = $(node).find(isDetailsMode ? 'span.af-date-display' : 'input.af-date');
        if (!this.root) {
            const $container = $input.parent();
            this.root = createRoot($container[0]);
        }
        const dateComponent = React.createElement(DateField, { ariaLabel: this.options.ariaLabel, ariaLabelledBy: this.options.ariaLabelledBy, detailsMode: isDetailsMode, disabled: this._ko.unwrap((_b = this.options.disabled) !== null && _b !== void 0 ? _b : false), enabled: this._ko.unwrap((_c = this.options.enabled) !== null && _c !== void 0 ? _c : true), id: this.options.id, knockout: this._ko, maxDate: this._maxDate, minDate: this._minDate, postTime: this._ko.unwrap(this.options.postTime), value: this.value, onChange: (newValue) => {
                this._rawValue(newValue);
                if (this.options.onChange) {
                    this.options.onChange(newValue);
                }
            } });
        this.root.render(dateComponent);
    }
}
export function getTemplate() {
    return require('Shared/Components/Fields/DateField/DateField.ko.html'); //eslint-disable-line
}
export function registerKnockoutDateField(knockout) {
    knockout.bindingHandlers['af-date'] = {
        init: function (element, valueAccessor) {
            const $el = $(element);
            const bound = valueAccessor();
            const dateFieldOptions = {};
            for (const [k, v] of Object.entries(bound)) {
                dateFieldOptions[k] = knockout.unwrap(v);
            }
            dateFieldOptions.knockout = knockout;
            dateFieldOptions.value = bound.value;
            if (bound.max) {
                dateFieldOptions.max = bound.max;
            }
            if (bound.min) {
                dateFieldOptions.min = bound.min;
            }
            const dateField = new DateFieldKnockout(dateFieldOptions);
            const template = getTemplate();
            const $newNode = $('<span></span>');
            $newNode.html(template);
            $el.after($newNode);
            $el.remove();
            const container = $newNode[0];
            knockout.applyBindingsToDescendants(dateField, container);
            dateField.koDescendantsComplete(container);
        }
    };
}
window.afKnockout = window.afKnockout || {};
window.afKnockout.registerDateField = registerKnockoutDateField;
