import 'jquery-validation';
import 'jquery-validation-unobtrusive';
export class UnobtrusiveValidation {
    constructor(form) {
        const formElement = $(form);
        const $validator = formElement.data('validator');
        if ($validator && $validator.settings.errorPlacement) {
            this.originalOnError = $validator.settings.errorPlacement;
            $validator.settings.errorPlacement = this.onError;
        }
        if ($validator && $validator.settings.success) {
            this.originalOnSuccess = $validator.settings.success;
            $validator.settings.success = this.onSuccess;
        }
    }
    // the MS unobtrusive js uses the container before checking to see if it's undefined
    onSuccess(error) {
        if (error.data('unobtrusiveContainer')) {
            this.originalOnSuccess(error);
        }
    }
    // the MS unobtrusive js tries to use the eror span the to see if it's undefined
    onError(label, element) {
        if (this.originalOnError) {
            const $errorSpan = $(`[data-valmsg-for='${element[0].name}']`);
            if ($errorSpan.length > 0) {
                this.originalOnError(label, element);
                return;
            }
        }
        label.insertAfter(element); // this is the default behavior in jquery.validate.js
    }
    static addToAllForms() {
        for (const form of $('form').toArray()) {
            new UnobtrusiveValidation(form);
        }
    }
}
