import ko from 'knockout';
import 'knockout.validation';
//--Used for single-use custom validation rules, especially if they're dependent on multiple fields
ko.validation.rules.customRule = {
    validator: function (val, customRule) {
        return !customRule(val);
    },
    message: function (customRule, observable) {
        return customRule(observable());
    }
};
ko.validation.registerExtenders();
export function configureCustomRule(observable, customRule) {
    observable.extend({
        customRule: customRule
    });
}
export function configureMaxLength(observable, maxLength, message, onlyIf) {
    observable.extend({
        maxLength: {
            params: maxLength,
            message: message,
            onlyIf: onlyIf
        }
    });
}
export function configureMinLength(observable, minLength, message, onlyIf) {
    observable.extend({
        minLength: {
            params: minLength,
            message: message,
            onlyIf: onlyIf
        }
    });
}
export function configureMinimumValue(observable, min, message, onlyIf) {
    observable.extend({
        min: {
            params: min,
            message: message
        },
        required: {
            onlyIf: onlyIf
        }
    });
}
export function configureRange(observable, min, max, message, onlyIf) {
    observable.extend({
        min: min,
        max: max,
        required: {
            message: message,
            onlyIf: onlyIf
        }
    });
}
export function configureRequired(observable, message, onlyIf) {
    observable.extend({
        required: {
            message: message,
            onlyIf: onlyIf
        }
    });
}
export function configurePattern(observable, pattern, message, onlyIf) {
    observable.extend({
        pattern: {
            params: pattern,
            message: message,
            onlyIf: onlyIf
        }
    });
}
export function configureAlphaNumeric(observable, message, onlyIf) {
    configurePattern(observable, /^[A-z0-9]*$/, message, onlyIf);
}
