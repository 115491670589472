import '@progress/kendo-ui/js/kendo.combobox.js';
import { pureComputed } from 'knockout';
import { interSubscribe } from 'Shared/Scripts/Components/Contracts/ComponentOptions';
const defaultOpts = {
    placeholder: 'Select...',
    size: 40,
    disableOnLoad: false
};
export class ComboBox {
    constructor(options) {
        this.options = defaultOpts;
        this.options = { ...defaultOpts, ...options };
        if (this.options.width) {
            this.options.size = undefined;
        }
        this.wideWidth = this.options.width === 'wide';
        this.maxWidth = this.options.width === 'max';
        if (this.options.lookupContainer) {
            this.lookupContainer = this.options.lookupContainer;
            this.postValue = pureComputed(() => {
                const value = this.lookupContainer.value();
                if (value !== undefined) {
                    return `${value}`;
                }
                else {
                    return undefined;
                }
            });
            this.value = pureComputed({
                read: () => this.lookupContainer.value(),
                write: (newValue) => {
                    var _a, _b;
                    (_a = this._comboBox) === null || _a === void 0 ? void 0 : _a.dataSource.filter([]); //--Clear the filters, otherwise the new value might not be in the list of possible items that the select() below iterates through
                    (_b = this._comboBox) === null || _b === void 0 ? void 0 : _b.select((dataItem) => this.getDataValue(dataItem) === newValue);
                }
            });
            this.displayValue = pureComputed(() => { var _a; return (_a = this.lookupContainer.options().find((x) => x[this.lookupContainer.dataValueField] === this.value())) === null || _a === void 0 ? void 0 : _a[this.lookupContainer.dataTextField]; });
            interSubscribe(this.lookupContainer.value, this.value);
        }
        else {
            throw 'A lookup container is required';
        }
    }
    koDescendantsComplete(node) {
        const $comboBox = $(node).find('input[type="text"]').kendoComboBox({
            dataTextField: this.lookupContainer.dataTextField,
            dataValueField: this.lookupContainer.dataValueField,
            filter: 'contains',
            minLength: 1,
            ignoreCase: true,
            highlightFirst: false,
            placeholder: this.options.placeholder,
            dataSource: this.lookupContainer.options(),
            change: () => {
                var _a;
                this.lookupContainer.value(this.getDataValue((_a = this._comboBox) === null || _a === void 0 ? void 0 : _a.dataItem()));
            },
            enable: !this.options.disableOnLoad
        });
        this._comboBox = $comboBox === null || $comboBox === void 0 ? void 0 : $comboBox.data('kendoComboBox');
        this.lookupContainer.options.subscribe((options) => {
            var _a, _b, _c;
            if (this.options.disableOnLoad) {
                (_a = this._comboBox) === null || _a === void 0 ? void 0 : _a.enable(true);
            }
            (_b = this._comboBox) === null || _b === void 0 ? void 0 : _b.dataSource.data(options);
            (_c = this._comboBox) === null || _c === void 0 ? void 0 : _c.refresh();
        });
        $(node).find('input[title]').each((_, input) => input.removeAttribute('title'));
        //--Deleting and exiting out of combobox does not change the value of the bound observable,
        //	causing old data to be retained so long as the previous entry was valid;...
        //	Messes with required and what not unless you force evaluation on blur
        $comboBox.blur(() => {
            var _a;
            if (!((_a = this._comboBox) === null || _a === void 0 ? void 0 : _a.dataItem())) {
                this.value(undefined);
                this.value.notifySubscribers();
            }
        });
    }
    getDataValue(dataItem) {
        return dataItem === null || dataItem === void 0 ? void 0 : dataItem[this.lookupContainer.dataValueField];
    }
}
