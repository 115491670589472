// No type declaration files exist for PNotify.
const PNotify = require('pnotify/dist/es/PNotify').default; // eslint-disable-line @typescript-eslint/no-var-requires
// Must be included for close button to appear.
const PNotifyButtons = require('pnotify/dist/es/PNotifyButtons').default; // eslint-disable-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unused-vars
import { Guid } from 'guid-typescript';
import 'pnotify/dist/PNotifyBrightTheme.css';
import '../Styles/notifications.scss';
PNotify.defaults.delay = 2000;
PNotify.defaults.icons = 'fontawesome5';
PNotify.defaults.titleTrusted = true;
PNotify.defaults.textTrusted = true;
PNotify.defaults.modules = {
    Buttons: {
        sticker: false
    }
};
function createNoticeOptions(title, text, hide = true) {
    return {
        title: `${title}`,
        text: `${text}`,
        addClass: 'af-notification-notice',
        hide: hide,
        icon: 'af-icon af-notification-notice'
    };
}
export function showNotice(title, text, hide = true) {
    return PNotify.notice(createNoticeOptions(title, text, hide));
}
function createInfoOptions(title, text, hide = true) {
    return {
        title: `${title}`,
        text: `${text}`,
        addClass: 'af-notification-info',
        hide: hide,
        icon: 'af-icon af-notification-info'
    };
}
export function showInfo(title, text, hide = true) {
    return PNotify.info(createInfoOptions(title, text, hide));
}
function createSuccessOptions(title, text, hide = true) {
    return {
        title: `${title}`,
        text: `${text}`,
        addClass: 'af-notification-success',
        hide: hide,
        icon: 'af-icon af-notification-success'
    };
}
export function showSuccess(title, text, hide = true) {
    return PNotify.success(createSuccessOptions(title, text, hide));
}
function createErrorOptions(title, text, delay = PNotify.defaults.delay, hide = false) {
    return {
        title: `${title}`,
        text: `${text}`,
        addClass: 'af-notification-error',
        delay: delay,
        hide: hide,
        icon: 'af-icon af-notification-error'
    };
}
export function showError(title, text, delay = PNotify.defaults.delay, hide = false) {
    return PNotify.error(createErrorOptions(title, text, delay, hide));
}
function createReminderOptions(title, text, hide = false) {
    return {
        title: `${title}`,
        text: `${text}`,
        addClass: 'af-notification-reminder',
        hide: hide,
        icon: 'af-icon af-notification-reminder'
    };
}
export function showReminder(title, text, hide = false) {
    return PNotify.info(createReminderOptions(title, text, hide));
}
export function removeAllNotifications() {
    PNotify.removeAll();
}
function retrievePersistentNotifications() {
    const notifications = [];
    const keysRetrieved = [];
    for (let i = 0; i < window.sessionStorage.length; i++) {
        const key = window.sessionStorage.key(i);
        if (key && key.indexOf('af-notification') === 0) {
            keysRetrieved.push(key);
            const item = window.sessionStorage.getItem(key);
            try {
                if (item) {
                    notifications.push(JSON.parse(item));
                }
            }
            catch {
                console.error(`Error deserializing persistent notification id: ${key}`); //eslint-disable-line no-console
            }
        }
    }
    for (const key of keysRetrieved) {
        window.sessionStorage.removeItem(key);
    }
    return notifications;
}
function retrieveModelStateNotifications() {
    const modelStateNotifications = $('#ModelStateNotifications > div.notification');
    const notifications = [];
    // .toArray() needed for IE compatibility.
    for (const notification of modelStateNotifications.toArray()) {
        const message = notification.innerText;
        const classList = notification.classList;
        if (classList.contains('notice')) {
            notifications.push({ ...{ type: 'notice' }, ...createNoticeOptions('Notice', message) });
        }
        else if (classList.contains('information')) {
            notifications.push({ ...{ type: 'info' }, ...createInfoOptions('Info', message) });
        }
        else if (classList.contains('success')) {
            notifications.push({ ...{ type: 'success' }, ...createSuccessOptions('Success', message) });
        }
        else if (classList.contains('error')) {
            notifications.push({ ...{ type: 'error' }, ...createErrorOptions('Error', message) });
        }
        else if (classList.contains('reminder')) {
            notifications.push({ ...{ type: 'reminder' }, ...createReminderOptions('Reminder', message) });
        }
    }
    modelStateNotifications.remove();
    return notifications;
}
export function showPersistentNotifications() {
    const notifications = retrievePersistentNotifications().concat(retrieveModelStateNotifications());
    for (const notification of notifications) {
        PNotify.alert(notification);
    }
}
export function addPersistentNotification(type, title, text, hide, delay) {
    const id = Guid.create().toString();
    let notification = { type: type };
    switch (type) {
        case 'notice':
            notification = { ...notification, ...createNoticeOptions(title, text, hide) };
            break;
        case 'info':
            notification = { ...notification, ...createInfoOptions(title, text, hide) };
            break;
        case 'success':
            notification = { ...notification, ...createSuccessOptions(title, text, hide) };
            break;
        case 'error':
            notification = { ...notification, ...createErrorOptions(title, text, delay, hide) };
            break;
        case 'reminder':
            notification = { ...notification, ...createReminderOptions(title, text, hide) };
            break;
        default:
            break;
    }
    window.sessionStorage.setItem(`af-notification:${id}`, JSON.stringify(notification));
    return id;
}
export function removePersistentNotification(id) {
    window.sessionStorage.removeItem(`af-notification:${id}`);
}
export function legacyHandler(type, title, text, hide = true) {
    // necessary for ko form submit handlers in FF, otherwise the notif disappears immediately
    // https://trello.com/c/OSRtF6W5
    setTimeout(() => {
        switch (type) {
            case 'info':
                showInfo(title, text, hide);
                break;
            case 'notice':
                showNotice(title, text, hide);
                break;
            case 'error':
                showError(title, text, undefined, hide);
                break;
            case 'success':
                showSuccess(title, text, hide);
                break;
            case 'reminder':
                showReminder(title, text, hide);
                break;
        }
    });
}
