import { createTheme, ThemeProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ko from 'knockout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AmpliFundBaseTheme } from 'Shared/Components/Fields/AmpliFundBaseTheme';
import { DateTimeFormat, getDateTimeMoment, getDateTimeString } from 'Shared/Scripts/DateTimeLogic';
import { getCurrentTimeZoneName } from 'Shared/Scripts/TimeZoneLogic';
import './DateTimeField.scss';
export class DateTimeFieldProperties {
    constructor() {
        this.ariaLabel = undefined;
        this.ariaLabelledBy = undefined;
        this.detailsMode = false;
        this.detailsModeCanFocusOnTabbing = false;
        this.detailsModeExcludeTime = false;
        this.disabled = false;
        this.enabled = true;
        this.id = undefined;
        this.knockout = undefined;
        this.label = undefined;
        this.max = undefined;
        this.min = undefined;
        this.name = undefined;
        this.onChange = undefined;
        this.nestedLayout = false;
        this.showTimeZone = false;
        this.utcDisplay = false;
        this.value = undefined;
    }
}
const calendarPickerFontSize = 12;
const dateTimeTheme = createTheme(AmpliFundBaseTheme, {
    components: {
        MuiDayCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    fontSize: calendarPickerFontSize
                }
            }
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    fontSize: calendarPickerFontSize
                }
            }
        }
    }
});
const buildMoment = (value) => {
    return getDateTimeMoment(value ? (!moment.isMoment(value) ? moment.utc(value) : value.utc()) : null);
};
const configureObservables = (val, setStateFunc, knockout, asMoment = false) => {
    setStateFunc(asMoment ? buildMoment(knockout.unwrap(val)) : knockout.unwrap(val));
    if (knockout.isSubscribable(val)) {
        val.subscribe((newVal) => {
            setStateFunc(asMoment ? buildMoment(newVal) : newVal);
        });
    }
};
export function DateTimeField({ ariaLabel, ariaLabelledBy, detailsMode, detailsModeCanFocusOnTabbing, detailsModeExcludeTime, disabled, enabled, id, knockout, label, max, min, name, nestedLayout, onChange, showTimeZone, utcDisplay, value }) {
    var _a;
    const _ko = knockout !== null && knockout !== void 0 ? knockout : ko;
    const [stateAriaLabel, setStateAriaLabel] = useState(_ko.unwrap(ariaLabel));
    useEffect(() => {
        configureObservables(ariaLabel, setStateAriaLabel, _ko);
    }, [_ko.unwrap(ariaLabel)]);
    const [stateAriaLabelledBy, setStateAriaLabelledBy] = useState(_ko.unwrap(ariaLabelledBy));
    useEffect(() => {
        configureObservables(ariaLabelledBy, setStateAriaLabelledBy, _ko);
    }, [_ko.unwrap(ariaLabelledBy)]);
    const [stateDetailsMode, setStateDetailsMode] = useState(_ko.unwrap(detailsMode));
    useEffect(() => {
        configureObservables(detailsMode, setStateDetailsMode, _ko);
    }, [_ko.unwrap(detailsMode)]);
    const [stateDetailsModeCanFocusOnTabbing, setStateDetailsModeCanFocusOnTabbing] = useState(_ko.unwrap(detailsModeCanFocusOnTabbing));
    useEffect(() => {
        configureObservables(detailsModeCanFocusOnTabbing, setStateDetailsModeCanFocusOnTabbing, _ko);
    }, [_ko.unwrap(detailsModeCanFocusOnTabbing)]);
    const [stateDetailsModeExcludeTime, setStateDetailsModeExcludeTime] = useState(_ko.unwrap(detailsModeExcludeTime));
    useEffect(() => {
        configureObservables(detailsModeExcludeTime, setStateDetailsModeExcludeTime, _ko);
    }, [_ko.unwrap(detailsModeExcludeTime)]);
    const [stateDisabled, setStateDisabled] = useState(_ko.unwrap(disabled));
    useEffect(() => {
        configureObservables(disabled, setStateDisabled, _ko);
    }, [_ko.unwrap(disabled)]);
    const [stateEnabled, setStateEnabled] = useState(_ko.unwrap(enabled));
    useEffect(() => {
        configureObservables(enabled, setStateEnabled, _ko);
    }, [_ko.unwrap(enabled)]);
    const [stateId, setStateId] = useState(_ko.unwrap(id));
    useEffect(() => {
        configureObservables(id, setStateId, _ko);
    }, [_ko.unwrap(id)]);
    const [stateLabel, setStateLabel] = useState(_ko.unwrap(label));
    useEffect(() => {
        configureObservables(label, setStateLabel, _ko);
    }, [_ko.unwrap(label)]);
    const [stateMax, setStateMax] = useState(buildMoment(_ko.unwrap(max)));
    useEffect(() => {
        configureObservables(max, setStateMax, _ko, true);
    }, [_ko.unwrap(max)]);
    const [stateMin, setStateMin] = useState(buildMoment(_ko.unwrap(min)));
    useEffect(() => {
        configureObservables(min, setStateMin, _ko, true);
    }, [_ko.unwrap(min)]);
    const [stateName, setStateName] = useState(_ko.unwrap(name));
    useEffect(() => {
        configureObservables(name, setStateName, _ko);
    }, [_ko.unwrap(name)]);
    const [stateShowTimeZone, setStateShowTimeZone] = useState(_ko.unwrap(showTimeZone));
    useEffect(() => {
        configureObservables(showTimeZone, setStateShowTimeZone, _ko);
    }, [_ko.unwrap(showTimeZone)]);
    const [stateUtcDisplay, setStateUtcDisplay] = useState(_ko.unwrap(utcDisplay));
    useEffect(() => {
        configureObservables(utcDisplay, setStateUtcDisplay, _ko);
    }, [_ko.unwrap(utcDisplay)]);
    const [stateValue, setStateValue] = useState(buildMoment(_ko.unwrap(value)));
    useEffect(() => {
        configureObservables(value, setStateValue, _ko, true);
    }, [_ko.unwrap(value)]);
    const [workingValue, setWorkingValue] = useState(stateValue);
    const componentProperties = {
        format: DateTimeFormat.Standard,
        disabled: stateDisabled || stateEnabled === false,
        label: stateLabel,
        maxDateTime: stateMax !== null && stateMax !== void 0 ? stateMax : undefined,
        minDateTime: stateMin !== null && stateMin !== void 0 ? stateMin : undefined,
        name: stateName,
        onAccept: (val) => {
            setWorkingValue(val);
            onChange === null || onChange === void 0 ? void 0 : onChange(val);
        },
        onChange: (val) => {
            setWorkingValue(val);
        },
        slotProps: {
            field: {
                inputProps: {
                    'data-testid': 'date-time-component',
                    onBlur: () => {
                        onChange === null || onChange === void 0 ? void 0 : onChange(workingValue);
                    }
                },
                shouldRespectLeadingZeros: true
            }
        },
        timezone: stateUtcDisplay ? 'UTC' : 'system'
    };
    if (onChange !== undefined) {
        componentProperties.value = stateValue;
    }
    else {
        componentProperties.defaultValue = stateValue;
    }
    if (nestedLayout) {
        componentProperties.slotProps.popper = {
            disablePortal: true
        };
    }
    const fieldSection = (_a = componentProperties.slotProps) === null || _a === void 0 ? void 0 : _a.field;
    const inputSection = fieldSection.inputProps;
    inputSection.onFocus = function (event) {
        event.stopPropagation();
    };
    if (stateId) {
        fieldSection.id = stateId;
    }
    if (stateAriaLabel) {
        inputSection['aria-label'] = stateAriaLabel;
    }
    if (stateAriaLabelledBy) {
        inputSection['aria-labelledby'] = stateAriaLabelledBy;
    }
    const postingInput = stateName && React.createElement("input", { type: 'hidden', "data-testid": 'posting-input', name: stateName, value: getDateTimeString(stateValue, DateTimeFormat.ISO8601), "aria-label": stateAriaLabel });
    const tzLabelId = `${stateId !== null && stateId !== void 0 ? stateId : Date.now()}-offset-label`;
    const tzLabel = React.createElement("span", { id: tzLabelId, className: `af-utc-tz-label ${!stateShowTimeZone ? 'sr-only' : ''}` }, stateValue && `(${getCurrentTimeZoneName()})`);
    inputSection['aria-describedby'] = tzLabelId;
    return stateDetailsMode ?
        React.createElement("div", { className: 'af-detail date-time' },
            postingInput,
            React.createElement("span", { id: stateId, tabIndex: stateDetailsModeCanFocusOnTabbing ? 0 : undefined, "aria-describedby": tzLabelId, "data-testid": 'details-mode' }, getDateTimeString(stateValue, stateDetailsModeExcludeTime ? DateTimeFormat.ShortDate : undefined, stateUtcDisplay)),
            tzLabel) :
        React.createElement("div", { className: 'af-input date-time' },
            postingInput,
            React.createElement(ThemeProvider, { theme: dateTimeTheme },
                React.createElement(LocalizationProvider, { dateAdapter: AdapterMoment },
                    React.createElement(DateTimePicker, { ...componentProperties }))),
            tzLabel);
}
