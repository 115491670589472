import ko from 'knockout';
import { DateFieldKnockout, getTemplate as getDateFieldTemplate } from 'Shared/Components/Fields/DateField/DateField.ko';
import { DateTimeFieldKnockout, getTemplate as getDateTimeFieldTemplate } from 'Shared/Components/Fields/DateTimeField/DateTimeField.ko';
import { AttachmentContainer } from 'Shared/Scripts/Components/AttachmentContainer';
import { CheckBox } from 'Shared/Scripts/Components/CheckBox';
import { ComboBox } from 'Shared/Scripts/Components/ComboBox';
import { DeprecatedDateInput } from 'Shared/Scripts/Components/DeprecatedDateInput';
import { DistinctListBuilder } from 'Shared/Scripts/Components/DistinctListBuilder';
import { MultiSelect } from 'Shared/Scripts/Components/MultiSelect';
import { NumericInput } from 'Shared/Scripts/Components/NumericInput';
import { TabStrip } from 'Shared/Scripts/Components/TabStrip';
import { TextBox } from 'Shared/Scripts/Components/TextBox';
import { ToggleInput } from 'Shared/Scripts/Components/ToggleInput';
//--I'd prefer to use imports, but webpack says it can't find the modules,... ...even though it actually loads fine in the browser..
//import numericTemplate from 'Shared/Scripts/Components/Templates/ToggleInput.html';
//--Keep these registrations in alphebetical order
export function registerComponents() {
    function register(componentName, options) {
        if (ko.components.isRegistered(componentName)) {
            ko.components.unregister(componentName);
        }
        ko.components.register(componentName, options);
    }
    register('af-applicationbudget', {
        viewModel: {
            createViewModel: (params) => params.budget
        },
        template: require('Public/Components/ApplicationBudget.html')
    });
    register('af-attachmentcontainer', {
        viewModel: {
            createViewModel: (params) => new AttachmentContainer(params)
        },
        template: require('Shared/Scripts/Components/AttachmentContainer.html')
    });
    register('af-checkbox', {
        viewModel: {
            createViewModel: (params) => new CheckBox(params)
        },
        template: require('Shared/Scripts/Components/CheckBox.html')
    });
    register('af-combobox', {
        viewModel: {
            createViewModel: (params) => new ComboBox(params)
        },
        template: require('Shared/Scripts/Components/ComboBox.html')
    });
    register('af-currency', {
        viewModel: {
            createViewModel: (params) => new NumericInput({ output: 'currency', ...params })
        },
        template: require('Shared/Scripts/Components/NumericInput.html')
    });
    register('af-date', {
        viewModel: {
            createViewModel: (params) => new DateFieldKnockout(params)
        },
        template: getDateFieldTemplate()
    });
    register('af-datetime', {
        viewModel: {
            createViewModel: (params) => new DateTimeFieldKnockout(params)
        },
        template: getDateTimeFieldTemplate()
    });
    register('af-deprecated-date', {
        viewModel: {
            createViewModel: (params) => new DeprecatedDateInput(params)
        },
        template: require('Shared/Scripts/Components/DeprecatedDateInput.html')
    });
    register('af-distinctlistbuilder', {
        viewModel: {
            createViewModel: (params) => new DistinctListBuilder(params)
        },
        template: require('Shared/Scripts/Components/DistinctListBuilder.html')
    });
    register('af-multiselect', {
        viewModel: {
            createViewModel: (params) => new MultiSelect(params)
        },
        template: require('Shared/Scripts/Components/MultiSelect.html')
    });
    register('af-numeric', {
        viewModel: {
            createViewModel: (params) => new NumericInput({ output: 'number', ...params })
        },
        template: require('Shared/Scripts/Components/NumericInput.html')
    });
    register('af-percent', {
        viewModel: {
            createViewModel: (params) => new NumericInput({ output: 'percent', ...params })
        },
        template: require('Shared/Scripts/Components/NumericInput.html')
    });
    register('af-tabstrip', {
        viewModel: {
            createViewModel: (params) => new TabStrip(params)
        },
        template: require('Shared/Scripts/Components/TabStrip.html')
    });
    register('af-textbox', {
        viewModel: {
            createViewModel: (params) => new TextBox(params)
        },
        template: require('Shared/Scripts/Components/TextBox.html')
    });
    register('af-toggle', {
        viewModel: {
            createViewModel: (params) => new ToggleInput(params)
        },
        template: require('Shared/Scripts/Components/ToggleInput.html')
    });
}
