import moment from 'moment';
export var DateFormat;
(function (DateFormat) {
    DateFormat["Standard"] = "M/D/YYYY";
    DateFormat["ISO8601"] = "YYYY-MM-DD";
    DateFormat["Post"] = "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]";
    DateFormat["PostZero"] = "YYYY-MM-DD[T00:00:00.000Z]";
    DateFormat["PostNoon"] = "YYYY-MM-DD[T12:00:00.000Z]";
})(DateFormat || (DateFormat = {}));
export function addDaysToDate(date, days) {
    return moment(date).add(days, 'days').toDate();
}
// Copied into utility.dates.js (depreciated) - copy/paste method body there with updates from here.
export function getDateMoment(date) {
    if (!date) {
        return null;
    }
    let result = moment(date);
    // We can't really identify the source time zone if given a Date or number, but we can try from string sources
    // AS LONG AS those string sources aren't fake strings like the one's we get from kendo grid templates...
    const parseString = (str) => moment.parseZone(str);
    if (typeof date === 'string' && !date.match(/\/Date\(\d+\)\//)) {
        result = parseString(date);
    }
    else if (moment.isMoment(date)) {
        // We'll try and rebuild the moment object the way we want to handle our fuzzy dates...
        const creationData = date.creationData();
        if (typeof creationData.input === 'string') {
            result = date.clone();
        }
    }
    else {
        // When given a non-string-based input, we can only work with a couple of assumptions...
        // if the UTC version's time is 00:00:00, we're going to assume it was originally saved in UTC...
        // otherwise, we're just going to assume it is a local instance
        const local = moment.isMoment(date) ? date.clone().local() : moment(date);
        const utc = local.clone().utc();
        if (local.date() !== utc.date() && (utc.hours() + utc.minutes() + utc.seconds() === 0)) {
            result = utc.utcOffset(local.utcOffset(), true);
        }
        else {
            result = local;
        }
    }
    // Return it in local mode, set to 12:00:00
    return result.local(true).startOf('day').hours(12);
}
export function getDateString(date, format = DateFormat.Standard) {
    var _a, _b;
    return (_b = (_a = getDateMoment(date)) === null || _a === void 0 ? void 0 : _a.format(format)) !== null && _b !== void 0 ? _b : '';
}
export function isBackendDateDefault(date) {
    if (!date) {
        return false;
    }
    const check = moment(date);
    return check.format('M') === '1' && check.format('D') === '1' && check.format('YYYY') === '0001';
}
export function isDateBefore(date, checkDate) {
    var _a, _b;
    return (_b = (_a = getDateMoment(date)) === null || _a === void 0 ? void 0 : _a.isBefore(checkDate, 'day')) !== null && _b !== void 0 ? _b : false;
}
export function isSameDate(a, b) {
    var _a;
    return (_a = a === null || a === void 0 ? void 0 : a.isSame(b, 'day')) !== null && _a !== void 0 ? _a : false;
}
export function isValidDate(a) {
    var _a;
    return (_a = a === null || a === void 0 ? void 0 : a.isValid()) !== null && _a !== void 0 ? _a : false;
}
window.afUtility = window.afUtility || {};
window.afUtility.dateLogic = {
    addDaysToDate: addDaysToDate,
    dateFormat: DateFormat,
    getDateMoment: getDateMoment,
    getDateString: getDateString,
    isBackendDateDefault: isBackendDateDefault,
    isSameDate: isSameDate,
    isValidDate: isValidDate
};
