export class Button {
    constructor(element) {
        this.button = element;
    }
    disable() {
        this.button.classList.add('disabled');
        this.button.disabled = true;
        return $.Deferred().always(() => {
            this.button.classList.remove('disabled');
            this.button.disabled = false;
        });
    }
    spin() {
        const deferredDisable = this.disable();
        this.button.classList.add('loading');
        return $.Deferred()
            .done(() => {
            deferredDisable.resolve();
        })
            .fail(() => {
            deferredDisable.reject();
        })
            .always(() => {
            this.button.classList.remove('loading');
        });
    }
}
export function detectActiveButtonRole() {
    const activeElement = document.activeElement;
    if (activeElement) {
        return ($(activeElement).is(':button') ? $(activeElement) : $('button:submit')).attr('data-role');
    }
    return undefined;
}
export function detectButtonRoleFromTarget(currentTarget) {
    if (currentTarget) {
        return ($(currentTarget).is(':button') ? $(currentTarget) : $('button:submit')).attr('data-role');
    }
    return undefined;
}
export class ButtonGroup {
    /**
     * Creates a group of buttons using the roles specified by the provided keys
     *
     * @param roleShouldSpin - If the role's value is true, the associated button(s) will spin; all buttons with specified roles will be disabled
     */
    constructor(container, roleShouldSpin) {
        this.buttons = [];
        for (const role in roleShouldSpin) {
            const element = $(container).find(`[data-role="${role}"].ui.button`);
            for (const btnElement of element.toArray()) {
                const button = new Button(btnElement);
                this.buttons.push(roleShouldSpin[role] ? button.spin() : button.disable());
            }
        }
        this.deferral = $.Deferred()
            .done(() => {
            this.buttons.forEach((e) => {
                e.resolve();
            });
        })
            .fail(() => {
            this.buttons.forEach((e) => {
                e.reject();
            });
        });
    }
    resolve() {
        this.deferral.resolve();
    }
    reject() {
        this.deferral.reject();
    }
}
