import ko, { observable, pureComputed } from 'knockout';
const defaultOpts = {};
export class CheckBox {
    constructor(options) {
        this.options = defaultOpts;
        this.value = observable(false);
        this.options = { ...defaultOpts, ...options };
        this.postValue = pureComputed(() => this.value() ? 'true' : 'false');
        this.displayValue = pureComputed(() => `${this.options.label ? `${this.options.label}: ` : ''}${this.value() ? 'Yes' : 'No'}`); //Apply label prior to yes/no if provided
        const value = options === null || options === void 0 ? void 0 : options.value;
        if (ko.isObservable(value)) {
            this.configureBackingObservable(value);
        }
        else {
            this.value(value !== null && value !== void 0 ? value : false);
        }
    }
    configureBackingObservable(backingObservable) {
        this.value(backingObservable());
        this.value.subscribe((newVal) => backingObservable(newVal));
        backingObservable.subscribe((newVal) => this.value(newVal));
    }
}
